import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {FC, useEffect, useState} from 'react'
import {ValueWithPrecisionDTO} from '../../../../models/DTOs/Indicadores/ValueWithPrecisionDTO'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Enumeracion} from '../../../../../_metronic/helpers/EnumeracionDTO'

type Props = {
  title: string
  indicador: {
    Codigo: string
    Nombre: string
    FormatoExpresion: string
  }
  valoresInformados: {
    ValorInformado?: ValueWithPrecisionDTO
    Estado?: string
    Periodo: string
    Mediana?: ValueWithPrecisionDTO
    CantMediciones: number
  }[]
  evaluacionesGrupoValores: {
    Periodo: string
    Mediana?: ValueWithPrecisionDTO
    Percentil25?: ValueWithPrecisionDTO
    Percentil75?: ValueWithPrecisionDTO
    CantMediciones: number
    Mediciones?: ValueWithPrecisionDTO[]
    Estado?: Enumeracion
    [x: string]: any // [Pais, Region]
  }[]
}

const SeguimientoDesempenio: FC<Props> = ({
  title,
  valoresInformados,
  evaluacionesGrupoValores,
  indicador,
}) => {
  const [options, setOptions] = useState<any>({})

  const intl = useIntl()

  useEffect(() => {
    const series: any = [
      {
        name: intl.formatMessage({id: 'HEADER.MEDIAN'}),
        dashStyle: 'longdash',
        lineWidth: 0,
        lineColor: 'gray',
        color: '#212427',
        marker: {
          radius: 8,
        },
        enableMouseTracking: false,
        data: evaluacionesGrupoValores
          .filter(
            (valor, index) =>
              valor.Mediana?.Value !== undefined &&
              valoresInformados.find((v) => v.Periodo === valor.Periodo)?.ValorInformado?.Value !==
                undefined
          )
          .map((valor) => {
            const anio = +valor.Periodo.split('/')[1]
            const mes = +valor.Periodo.split('/')[0]

            return {
              valor: valor.Mediana!.Value.toFixed(valor.Mediana!.Precision),
              fecha: valor.Periodo,
              y: valor.Mediana!.Value,
              x: Date.UTC(anio, mes - 1),
              color: '#212427',
            }
          }),
        events: {
          legendItemClick: function (e: any) {
            return false
          },
        },
        legendColor: '#212427',
      },
      {
        name: intl.formatMessage({id: 'HEADER.REPORTED_VALUES'}),
        dashStyle: 'longdash',
        lineWidth: 1,
        lineColor: 'gray',
        color: 'gray',
        marker: {
          radius: 8,
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `<b>${intl.formatMessage({
            id: 'HEADER.VALUE',
          })}</b>: {point.valor} <br/> <b>${intl.formatMessage({
            id: 'HEADER.DATE',
          })}</b>: {point.fecha}`,
        },
        data: valoresInformados
          .filter(
            (valor, index) =>
              valor.ValorInformado?.Value !== undefined &&
              evaluacionesGrupoValores.find((v) => v.Periodo === valor.Periodo)?.Mediana?.Value !==
                undefined
          )
          .map((valor) => {
            const anio = +valor.Periodo.split('/')[1]
            const mes = +valor.Periodo.split('/')[0]
            const estado = evaluacionesGrupoValores.find((v) => v.Periodo === valor.Periodo)?.Estado
              ?.Name

            return {
              valor: valor.ValorInformado!.Value.toFixed(valor.ValorInformado!.Precision),
              fecha: valor.Periodo,
              y: valor.ValorInformado!.Value,
              x: Date.UTC(anio, mes - 1),
              color:
                estado === 'cuestionable'
                  ? '#ffc803'
                  : estado === 'aceptable'
                  ? '#1e9ef7'
                  : estado === 'excelente'
                  ? '#67cd89'
                  : 'gray',
            }
          }),
        events: {
          legendItemClick: function (e: any) {
            return false
          },
        },
      },
    ]

    evaluacionesGrupoValores.forEach((evaluacion, index) => {
      if (
        evaluacion.Mediana?.Value !== undefined &&
        valoresInformados.find((v) => v.Periodo === evaluacion.Periodo)?.ValorInformado?.Value !==
          undefined &&
        evaluacion.Percentil25?.Value !== undefined &&
        evaluacion.Percentil75?.Value !== undefined
      ) {
        const anio = +evaluacion.Periodo.split('/')[1]
        const mes = +evaluacion.Periodo.split('/')[0]
        series.push({
          name: `${intl.formatMessage({id: 'HEADER.RANGE'})} P<sub>25</sub> - P<sub>75</sub>`,
          color: '#212427',
          lineWidth: 2,
          lineColor: '#212427',
          clickable: false,
          showInLegend: index === 0,
          enableMouseTracking: false,
          zIndex: -1,
          marker: {
            radius: 0,
            enabled: false,
          },
          data: [
            {
              y: evaluacion.Percentil25.Value,
              x: Date.UTC(anio, mes - 1),
            },
            {
              y: evaluacion.Percentil75.Value,
              x: Date.UTC(anio, mes - 1),
            },
          ],
          events: {
            legendItemClick: function (e: any) {
              return false
            },
          },
        })
      }
    })

    setOptions({
      chart: {
        type: 'line',
        marginRight: 60,
        height: 400,
      },
      title: {
        text: undefined,
      },
      subtitle: {
        align: 'center',
        useHTML: true,
        text: `<div style="text-align: center; margin-bottom: 10px;">${
          valoresInformados[0].Periodo
        } - ${valoresInformados[valoresInformados.length - 1].Periodo}</div>`,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.VALUE'})} (${indicador.FormatoExpresion})`,
        },
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%m/%Y}',
          rotation: -45,
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function () {
              return false
            },
          },
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          marker: {
            symbol: 'circle',
          },
        },
      },
      legend: {
        useHTML: true,
      },
      series: series,
    })
  }, [])

  return (
    <div style={{pageBreakInside: 'avoid'}}>
      <label className='fw-bolder fs-3 mb-5'>{title}</label>
      {options.series?.every((s: any) => s.data.length > 0) ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <div className='alert alert-warning d-flex align-items-center'>
          <span className='svg-icon svg-icon-2hx svg-icon-warning me-4'>
            <KTSVG path='/media/icons/duotune/general/gen044.svg' />
          </span>
          <span>{intl.formatMessage({id: 'NOT_ENOUGH.DATA'})}</span>
        </div>
      )}
    </div>
  )
}

export default SeguimientoDesempenio
