import moment from 'moment'
import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {MedicionIndicadorDTO} from '../../../../models/DTOs/Mediciones/MedicionIndicadorDTO'
import {IndicadorModel} from '../../../../models/IndicadorModel'
import {MedicionHistorialModel} from '../../../../models/MedicionHistorialModel'
import {UpdateMedicionDTO} from '../../../../models/DTOs/Mediciones/UpdateMedicionDTO'

const MEDICIONESHISTORIAL_KEY = 'MedicionesHistorialTableMock'

export class MedicionesHistorialTableMock {
  public static table: Array<MedicionHistorialModel> = [
    {
      Id: 'rojo1',
      Fecha: '01/01/2023',
      FechaCarga: '10/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 80,
        Precision: 2,
      },
      Estado: 'rojo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'amarillo1',
      Fecha: '02/01/2023',
      FechaCarga: '02/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 110,
        Precision: 4,
      },
      Estado: 'amarillo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: false,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'verde1',
      Fecha: '03/01/2023',
      FechaCarga: '20/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 200,
        Precision: 2,
      },
      Estado: 'verde',
      Observaciones: 'Medicion tomada bajo condiciones perfectas',
      IsEditado: true,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'rojo2',
      Fecha: '04/01/2023',
      FechaCarga: '10/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 80,
        Precision: 2,
      },
      Estado: 'rojo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'amarillo2',
      Fecha: '05/01/2023',
      FechaCarga: '02/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 110,
        Precision: 4,
      },
      Estado: 'amarillo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: false,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'verde2',
      Fecha: '06/01/2023',
      FechaCarga: '20/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 200,
        Precision: 2,
      },
      Estado: 'verde',
      Observaciones: 'Medicion tomada bajo condiciones perfectas',
      IsEditado: true,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'rojo3',
      Fecha: '07/01/2023',
      FechaCarga: '10/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 80,
        Precision: 2,
      },
      Estado: 'rojo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'amarillo3',
      Fecha: '08/01/2023',
      FechaCarga: '02/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 110,
        Precision: 4,
      },
      Estado: 'amarillo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: false,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'verde3',
      Fecha: '09/01/2023',
      FechaCarga: '20/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 200,
        Precision: 2,
      },
      Estado: 'verde',
      Observaciones: 'Medicion tomada bajo condiciones perfectas',
      IsEditado: true,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'rojo4',
      Fecha: '10/01/2023',
      FechaCarga: '10/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 80,
        Precision: 2,
      },
      Estado: 'rojo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'amarillo4',
      Fecha: '11/01/2023',
      FechaCarga: '02/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 110,
        Precision: 4,
      },
      Estado: 'amarillo',
      Observaciones: '',
      IsEditado: false,
      HasCargaFueraTermino: false,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      Id: 'verde4',
      Fecha: '12/01/2023',
      FechaCarga: '20/01/2023',
      ValorObjetivo: {
        Tipo: 'menor',
        Valor: {
          Value: 100,
          Precision: 0,
        },
      },
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 150,
          Precision: 0,
        },
      },
      Valor: {
        Value: 200,
        Precision: 2,
      },
      Estado: 'verde',
      Observaciones: 'Medicion tomada bajo condiciones perfectas',
      IsEditado: true,
      HasCargaFueraTermino: true,
      IndicadorId: '3i2NDaWmqGVxjd',
      SedeId: 'sm2Ake7UIOpe231',
    },
  ]

  private static _table: Array<MedicionHistorialModel> = []

  private static save() {
    localStorage.setItem(MEDICIONESHISTORIAL_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const medicionesStorage = localStorage.getItem(MEDICIONESHISTORIAL_KEY)
    this._table = medicionesStorage !== null ? JSON.parse(medicionesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearMediciones(
    sedeId: string,
    mediciones: MedicionIndicadorDTO[],
    indicador: IndicadorModel
  ) {
    this.read()
    for (const medicion of mediciones) {
      this._table.push({
        Fecha: medicion.Fecha,
        IndicadorId: medicion.IndicadorId,
        Observaciones: medicion.Observaciones,
        Valor: medicion.Valor,
        SedeId: sedeId,
        Estado: 'verde',
        FechaCarga: moment().format('DD/MM/YYYY'),
        HasCargaFueraTermino: false,
        Id: randomString(15),
        IsEditado: false,
        ValorLimite: indicador.ValorLimite!,
        ValorObjetivo: indicador.ValorObjetivo!,
      })
    }
    this.save()
  }
  static updateMedicion(
    sedeId: string,
    indicadorId: string,
    medicionId: string,
    updateMedicion: UpdateMedicionDTO
  ) {
    const medicion = this._table.find(
      (m) => m.IndicadorId === indicadorId && m.SedeId === sedeId && m.Id === medicionId
    )
    if (!medicion) return

    medicion.Fecha = updateMedicion.Fecha
    medicion.Observaciones = updateMedicion.Observaciones
    medicion.Valor = updateMedicion.Valor

    this.save()
  }
}
