import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, tipoValorHandler} from '../../../../../_metronic/helpers'
import BulletComponent from '../../../../../_metronic/helpers/components/BulletComponent'
import {InterlaboratorioDTO} from '../../../../models/DTOs/Reportes/InterlaboratorioDTO'
import DesempenioObjetivoLimite from '../../../reportes/sede/interlaboratorio/DesempenioObjetivoLimite'
import Histograma from '../../../reportes/sede/interlaboratorio/Histograma'
import SeguimientoDesempenio from '../../../reportes/sede/interlaboratorio/SeguimientoDesempenio'
import TablaEvaluacion from '../../../../../_metronic/helpers/components/TablaEvaluacion'
import {getInterlaboratorio} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {showError} from '../../../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  sedeId: string
  indicadorId: string
  periodo: string
  sede: string
  institucion: string
  gruposValues: string[]
}

const InterlaboratorioPDF: FC<Props> = ({
  indicadorId,
  institucion,
  periodo,
  sede,
  sedeId,
  gruposValues,
}) => {
  const [reporte, setReporte] = useState<InterlaboratorioDTO>()

  const intl = useIntl()

  const valorInformadoActual = reporte?.ValorInformado
  const ultimaEvalucionValorObjetivoMediana =
    reporte?.EvaluacionesValorObjetivo &&
    reporte?.EvaluacionesValorObjetivo[reporte.EvaluacionesValorObjetivo.length - 1].Mediana

  const grupos = [
    {value: 'todos', label: intl.formatMessage({id: 'COMPARISONGROUPS.ALL'})},
    {value: 'pais', label: intl.formatMessage({id: 'COMPARISONGROUPS.COUNTRY'})},
    {value: 'region', label: intl.formatMessage({id: 'COMPARISONGROUPS.REGION'})},
    {value: 'institucion', label: intl.formatMessage({id: 'COMPARISONGROUPS.INSTITUTION'})},
  ].filter((g) => gruposValues.includes(g.value))

  useEffect(() => {
    getInterlaboratorio(indicadorId, sedeId, periodo)
      .then((r) => {
        setReporte(r.data)
      })
      .catch((e) => {
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      })
  }, [])

  document.body.style.backgroundColor = '#FFF'

  return reporte && valorInformadoActual ? (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 pt-8'>
          <div className='row'>
            <label className='form-label col-3'>
              {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
            </label>
            <label className='form-label col-3'>
              {intl.formatMessage({id: 'MENU.TITLE.BRANCH'})}
            </label>
            <label className='form-label col-3'>
              {intl.formatMessage({id: 'HEADER.INDICATOR'})}
            </label>
            <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.PERIOD'})}</label>
          </div>
          <div className='row mb-10 ml-2'>
            <span className='col-3 text-gray-500'>{institucion}</span>
            <span className='col-3 text-gray-500'>{sede}</span>
            <span className='col-3 text-gray-500'>{reporte?.Indicador.Nombre}</span>
            <span className='col-3 text-gray-500'>{periodo}</span>
          </div>
          <div className='row mb-5'>
            <>
              <div className='col'>
                <label className='fw-bolder fs-3 mb-5'>
                  {intl.formatMessage({
                    id: 'INTERLABORATORY.EVALUATION.DEFINED_TARGET_VALUE',
                  })}
                </label>
                <div className='row mb-3'>
                  <div className='col fs-6 fw-bolder mb-1'>
                    <span>{intl.formatMessage({id: 'HEADER.REPORTED_VALUE'})}:</span>
                  </div>
                  <div className='col fw-bold text-gray-600'>
                    <span>
                      {valorInformadoActual.Value.toFixed(valorInformadoActual.Precision)}
                    </span>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col fs-6 fw-bolder mb-1'>
                    <span>{intl.formatMessage({id: 'HEADER.STATUS'})}:</span>
                  </div>
                  <div className='col fw-bold text-gray-600'>
                    <span>
                      <BulletComponent color={reporte.Estado} />
                    </span>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col fs-6 fw-bolder mb-1'>
                    <span>{intl.formatMessage({id: 'HEADER.MEDIAN'})}:</span>
                  </div>
                  <div className='col fw-bold text-gray-600'>
                    <span>
                      {ultimaEvalucionValorObjetivoMediana?.Value ?? (
                        <span title={intl.formatMessage({id: 'INTERLABORATORY.NOT_MEDIAN'})}>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-1 svg-icon-danger'
                          />
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col fs-6 fw-bolder mb-1'>
                    <span>{intl.formatMessage({id: 'HEADER.TARGETVALUE'})}:</span>
                  </div>
                  <div className='col fw-bold text-gray-600'>
                    <span>
                      {tipoValorHandler(reporte.Indicador.ValorObjetivo.Tipo)}{' '}
                      {reporte.Indicador.ValorObjetivo.Valor.Value.toFixed(
                        reporte.Indicador.ValorObjetivo.Valor.Precision
                      )}
                    </span>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col fs-6 fw-bolder mb-1'>
                    <span>{intl.formatMessage({id: 'HEADER.LIMITVALUE'})}:</span>
                  </div>
                  <div className='col fw-bold text-gray-600'>
                    <span>
                      {tipoValorHandler(reporte.Indicador.ValorLimite.Tipo)}{' '}
                      {reporte.Indicador.ValorLimite.Valor.Value.toFixed(
                        reporte.Indicador.ValorLimite.Valor.Precision
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col'>
                <DesempenioObjetivoLimite
                  estado={reporte.Estado}
                  valorInformado={valorInformadoActual}
                  indicador={reporte.Indicador}
                  title={intl.formatMessage({id: 'HEADER.LIMITTARGETVALUES'})}
                  evaluacionValor={
                    reporte.EvaluacionesValorObjetivo[reporte.EvaluacionesValorObjetivo.length - 1]
                  }
                />
              </div>
            </>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 pt-8'>
          {grupos.find((g) => g.value === 'todos') && (
            <>
              <TablaEvaluacion
                title={intl.formatMessage({id: 'INTERLABORATORY.EVALUATION.ALL'})}
                titleGraph={intl.formatMessage({id: 'INTERLABORATORY.PERFORMANCE.ALL'})}
                valorInformadoActual={valorInformadoActual}
                estado={reporte.Estado}
                grupoComparacion={
                  reporte.GruposComparacionTodos[reporte.GruposComparacionTodos.length - 1]
                }
                indicador={reporte.Indicador}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'pais') && (
            <>
              <hr className='my-7' />
              <TablaEvaluacion
                title={intl.formatMessage({id: 'INTERLABORATORY.EVALUATION.COUNTRY'})}
                titleGraph={intl.formatMessage({id: 'INTERLABORATORY.PERFORMANCE.COUNTRY'})}
                valorInformadoActual={valorInformadoActual}
                estado={reporte.Estado}
                grupoComparacion={
                  reporte.GruposComparacionPais[reporte.GruposComparacionPais.length - 1]
                }
                indicador={reporte.Indicador}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'region') && (
            <>
              <hr className='my-7' />
              <TablaEvaluacion
                title={intl.formatMessage({id: 'INTERLABORATORY.EVALUATION.REGION'})}
                titleGraph={intl.formatMessage({id: 'INTERLABORATORY.PERFORMANCE.REGION'})}
                valorInformadoActual={valorInformadoActual}
                estado={reporte.Estado}
                grupoComparacion={
                  reporte.GruposComparacionRegion[reporte.GruposComparacionRegion.length - 1]
                }
                indicador={reporte.Indicador}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'institucion') && (
            <>
              <hr className='my-7' />
              <TablaEvaluacion
                title={intl.formatMessage({id: 'INTERLABORATORY.EVALUATION.INSTITUTION'})}
                titleGraph={intl.formatMessage({
                  id: 'INTERLABORATORY.PERFORMANCE.INSTITUTION',
                })}
                valorInformadoActual={valorInformadoActual}
                estado={reporte.Estado}
                grupoComparacion={
                  reporte.GruposComparacionInstitucion[
                    reporte.GruposComparacionInstitucion.length - 1
                  ]
                }
                indicador={reporte.Indicador}
              />
            </>
          )}
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 pt-8'>
          {grupos.find((g) => g.value === 'todos') && (
            <>
              <SeguimientoDesempenio
                title={intl.formatMessage({
                  id: 'INTERLABORATORY.PERFORMANCE_MONITORING.ALL',
                })}
                indicador={reporte.Indicador}
                evaluacionesGrupoValores={reporte.GruposComparacionTodos}
                valoresInformados={reporte.EvaluacionesValorObjetivo}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'pais') && (
            <>
              <hr className='my-7' />
              <SeguimientoDesempenio
                title={intl.formatMessage({
                  id: 'INTERLABORATORY.PERFORMANCE_MONITORING.COUNTRY',
                })}
                indicador={reporte.Indicador}
                evaluacionesGrupoValores={reporte.GruposComparacionPais}
                valoresInformados={reporte.EvaluacionesValorObjetivo}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'region') && (
            <>
              <hr className='my-7' />
              <SeguimientoDesempenio
                title={intl.formatMessage({
                  id: 'INTERLABORATORY.PERFORMANCE_MONITORING.REGION',
                })}
                indicador={reporte.Indicador}
                evaluacionesGrupoValores={reporte.GruposComparacionRegion}
                valoresInformados={reporte.EvaluacionesValorObjetivo}
              />
            </>
          )}
          {grupos.find((g) => g.value === 'institucion') && (
            <>
              <hr className='my-7' />
              <SeguimientoDesempenio
                title={intl.formatMessage({
                  id: 'INTERLABORATORY.PERFORMANCE_MONITORING.INSTITUTION',
                })}
                indicador={reporte.Indicador}
                evaluacionesGrupoValores={reporte.GruposComparacionInstitucion}
                valoresInformados={reporte.EvaluacionesValorObjetivo}
              />
            </>
          )}
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 pt-8'>
          <Histograma
            grupos={grupos.map((g) => g.value)}
            medicionesTodos={reporte.GruposComparacionTodos[
              reporte.GruposComparacionTodos.length - 1
            ].Mediciones?.map((m) => m.Value)}
            medicionesPais={reporte.GruposComparacionPais[
              reporte.GruposComparacionPais.length - 1
            ].Mediciones?.map((m) => m.Value)}
            medicionesRegion={reporte.GruposComparacionRegion[
              reporte.GruposComparacionRegion.length - 1
            ].Mediciones?.map((m) => m.Value)}
            medicionesInstitucion={reporte.GruposComparacionInstitucion[
              reporte.GruposComparacionInstitucion.length - 1
            ].Mediciones?.map((m) => m.Value)}
            indicador={reporte.Indicador}
          />
        </div>
      </div>
    </>
  ) : (
    <div className='alert alert-warning d-flex align-items-center'>
      <span className='svg-icon svg-icon-2hx svg-icon-warning me-4'>
        <KTSVG path='/media/icons/duotune/general/gen040.svg' />
      </span>
      <span>{intl.formatMessage({id: 'NOT_FOUND.DATA_PERIOD'})}</span>
    </div>
  )
}

export default InterlaboratorioPDF
