import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const PruebaProcesandoResultados: FC = () => {
  const intl = useIntl()

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
            <a href='/dashboard' className='mb-10 pt-lg-1'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo_gm_qi.png')}
                className='h-150px'
              />
            </a>
            <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
              {intl.formatMessage({id: 'PP.PROCESSING_RESULTS'})}
            </h1>
            <div className='fw-bold fs-3 text-gray-600 mb-15'>
              {intl.formatMessage({id: 'PP.PROCESSING_RESULTS_MESSAGE'})}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PruebaProcesandoResultados
