import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {useContext} from 'react'
import ModalContext from '../ticket/ModelContext'
import {AsideMenuItemAnchor} from './AsideMenuItemAnchor'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {toggleModal} = useContext(ModalContext)

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.TITLE.BRANCH'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        id='sedeIndicadores'
        to='/sede/indicadores'
        icon='/media/icons/duotune/text/txt001.svg'
        title={intl.formatMessage({id: 'MENU.INDICATOR'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        title={intl.formatMessage({id: 'MENU.REPORT'})}
        to='/individualreports'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/files/fil003.svg'
      >
        <AsideMenuItem
          id='GraficoControl'
          to='/individualreports/controlgraph'
          hasBullet
          title={intl.formatMessage({id: 'MENU.REPORT.CONTROLGRAPH'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='TablaSeguimiento'
          to='/individualreports/tranckingtable'
          hasBullet
          title={intl.formatMessage({id: 'MENU.REPORT.TRACKINGTABLE'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='Auditoria'
          to='/individualreports/audit'
          hasBullet
          title={intl.formatMessage({id: 'MENU.REPORT.AUDIT'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='interlab'
          to='/individualreports/interlab'
          hasBullet
          title={intl.formatMessage({id: 'MENU.REPORT.INTERLAB'})}
          fontIcon='bi-app-indicator'
        />
      </AsideMenuItemWithSub>

      {/* {user.Rol === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.INSTITUTION'})}
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            title={intl.formatMessage({id: 'MENU.REPORT'})}
            to='/generalreports'
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/files/fil004.svg'
          >
            <AsideMenuItem
              id='generalInstitucion'
              to='/generalreports/reportinstitution'
              hasBullet
              title={intl.formatMessage({id: 'MENU.REPORT.INSTITUTION'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              id='TablaResumenIndicador'
              to='/generalreports/indicatortablesummary'
              hasBullet
              title={intl.formatMessage({id: 'MENU.REPORT.INDICATORSUMMARY'})}
              fontIcon='bi-app-indicator'
            />
          </AsideMenuItemWithSub>
        </>
      )} */}

      {user.Rol === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.ADMIN'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            id='listaIndicadores'
            to='/indicators'
            icon='/media/icons/duotune/general/gen032.svg'
            title={intl.formatMessage({id: 'MENU.INDICATORS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaUsuarios'
            to='/userslist'
            icon='/media/icons/duotune/communication/com005.svg'
            title={intl.formatMessage({id: 'MENU.USERS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaSedes'
            to='/sedeslist'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.BRANCHES'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='ajustesInstitucion'
            to='/institution'
            icon='/media/icons/duotune/coding/cod001.svg'
            title={intl.formatMessage({id: 'MENU.INSTITUTION'})}
            fontIcon='bi-app-indicator'
          />
        </>
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.TITLE.SUPPORT'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        id='supTickets'
        to='/support/tickets'
        icon='/media/icons/duotune/communication/com007.svg'
        title={intl.formatMessage({id: 'MENU.TICKETS'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemAnchor
        id='newTicket'
        icon='/media/icons/duotune/general/gen035.svg'
        title={intl.formatMessage({id: 'MENU.NEW_TICKET'})}
        fontIcon='bi-app-indicator'
        onClick={toggleModal}
      />
    </>
  )
}
