import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {FC, useEffect, useState} from 'react'
import {LimiteDTO} from '../../../../models/DTOs/Indicadores/LimiteDTO'
import {useIntl} from 'react-intl'
import {formatoExpresionHandler} from '../../../../../_metronic/helpers'

type Props = {
  grupos: string[]
  medicionesTodos?: number[]
  medicionesPais?: number[]
  medicionesRegion?: number[]
  medicionesInstitucion?: number[]
  indicador: {
    Codigo: string
    Nombre: string
    FormatoExpresion: string
    ValorObjetivo: LimiteDTO
    ValorLimite: LimiteDTO
  }
}

type Intervalo = {
  min: number
  max: number
  minInclusive: boolean
  maxInclusive: boolean
}

const Histograma: FC<Props> = ({
  grupos,
  medicionesTodos,
  medicionesPais,
  medicionesRegion,
  medicionesInstitucion,
  indicador,
}) => {
  const [options, setOptions] = useState({})

  const intl = useIntl()

  const cantIntervalos = 8
  const min = Math.min(...medicionesTodos!)
  const max = Math.max(...medicionesTodos!)
  const anchoIntervalo = Number(((max - min) / (cantIntervalos - 2)).toFixed(5));
  const intervalos: Intervalo[] = [...Array(cantIntervalos)].map<Intervalo>((_, index) => {
    const minIntervalo = index === 0 ? min - anchoIntervalo : min + (index - 1) * anchoIntervalo
    return {
      min: Number(minIntervalo.toFixed(3)),
      max: Number((minIntervalo + anchoIntervalo).toFixed(3)),
      minInclusive: index < cantIntervalos - 1,
      maxInclusive: index === cantIntervalos - 2,
    }
  })

  useEffect(() => {
    const categoriasX = intervalos.map(
      (i) =>
        `${i.minInclusive ? '[' : '('}${i.min.toFixed(2)} ; ${i.max.toFixed(2)}${
          i.maxInclusive ? ']' : ')'
        }`
    )

    const series: any = []

    const setSerie = (grupo: string, color: string, nombre: string, mediciones?: number[]) => {
      if (grupos.includes(grupo)) {
        series.push({
          name: nombre,
          color: color,
          data: intervalos.map((intervalo) => {            
            return mediciones?.filter(
              (m) =>
                (intervalo.minInclusive ? m >= intervalo.min : m > intervalo.min) &&
                (intervalo.maxInclusive ? m <= intervalo.max : m < intervalo.max)
            ).length
          }),
        })
      }
    }

    setSerie('todos', 'gray', intl.formatMessage({id: 'HEADER.GROUP.ALL'}), medicionesTodos)
    setSerie('region', 'orange', intl.formatMessage({id: 'HEADER.GROUP.REGION'}), medicionesRegion)
    setSerie('pais', 'green', intl.formatMessage({id: 'HEADER.GROUP.COUNTRY'}), medicionesPais)
    setSerie(
      'institucion',
      'blue',
      intl.formatMessage({id: 'HEADER.GROUP.INSTITUTION'}),
      medicionesInstitucion
    )

    setOptions({
      chart: {
        type: 'column',
        marginRight: 60,
        height: 500,
      },
      title: {
        text: intl.formatMessage({id: 'HEADER.HISTOGRAM'}),
      },
      subtitle: {
        align: 'center',
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.FREQUENCY'})}`,
        },
      },
      xAxis: {
        type: 'category',
        title: {
          text: `${intl.formatMessage({id: 'HEADER.VALUE'})} (${formatoExpresionHandler(
            indicador.FormatoExpresion,
            intl
          )})`,
        },
        categories: categoriasX,
      },
      plotOptions: {
        column: {
          grouping: true,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          tooltip: {
            headerFormat: null,
            pointFormat: `<b>${intl.formatMessage({id: 'HEADER.FREQUENCY'})}</b>: {point.y}`,
          },
        },
      },
      series: series,
    })
  }, [])

  return (
    <div style={{pageBreakInside: 'avoid'}}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default Histograma
