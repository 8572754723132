import {FC} from 'react'
import {useLocation} from 'react-router-dom'
import {Error404} from '../../../app/modules/errors/components/Error404'
import GraficosControlPDF from '../../../app/pages/external/reportes/graficos-control/GraficosControlPDF'
import {setTempStorage} from '../StorageHelper'
import {setLanguageTemp} from '../../i18n/Metronici18n'
import TablaSeguimientoPDF from '../../../app/pages/external/reportes/tabla-seguimiento/TablaSeguimientoPDF'
import AuditoriaPDF from '../../../app/pages/external/reportes/auditoria/AuditoriaPDF'
import InterlaboratorioPDF from '../../../app/pages/external/reportes/interlaboratorio/InterlaboratorioPDF'
import GeneralInstitucionPDF from '../../../app/pages/external/reportes/general-institucion/GeneralInstitucionPDF'
import ResumenIndicadorPDF from '../../../app/pages/external/reportes/resumen-indicador/ResumenIndicadorPDF'

const GetExternalComponent: FC = () => {
  const search = useLocation().search
  const params = new URLSearchParams(search)

  const action = params.get('action')

  const dataEncoded = params.get('data')
  if (!dataEncoded) return <Error404 />

  let dataDecoded

  try {
    dataDecoded = JSON.parse(atob(dataEncoded))
  } catch {
    return <Error404 />
  }

  const sedeId: string = dataDecoded.SedeEncoded
  const sedesId: string[] = dataDecoded.SedesEncoded?.split('-')
  const indicadorId: string = dataDecoded.IndicadorEncoded
  const desde: string = dataDecoded.Desde?.replaceAll('-', '/')
  const hasta: string = dataDecoded.Hasta?.replaceAll('-', '/')
  const accessToken: string = dataDecoded.AccessToken[0]?.split(' ')[1]
  const sede: string = dataDecoded.Sede
  const institucion: string = dataDecoded.Institucion
  const lang: string = dataDecoded.Lang
  const grupos: string[] = dataDecoded.Grupos?.split('-')
  const periodo: string = dataDecoded.Periodo?.replace('-', '/')

  setLanguageTemp(lang)
  setTempStorage(accessToken)

  switch (action) {
    case 'REPORT_PDF_GRAFICOS_CONTROL':
      return (
        <GraficosControlPDF
          sedeId={sedeId}
          indicadorId={indicadorId}
          desde={desde}
          hasta={hasta}
          sede={sede}
          institucion={institucion}
        />
      )
    case 'REPORT_PDF_TABLA_SEGUIMIENTO':
      return (
        <TablaSeguimientoPDF
          sedeId={sedeId}
          indicadorId={indicadorId}
          desde={desde}
          hasta={hasta}
          sede={sede}
          institucion={institucion}
        />
      )
    case 'REPORT_PDF_AUDITORIA':
      return (
        <AuditoriaPDF
          sedeId={sedeId}
          indicadorId={indicadorId}
          desde={desde}
          hasta={hasta}
          sede={sede}
          institucion={institucion}
        />
      )
    case 'REPORT_PDF_INTERLABORATORIO':
      return (
        <InterlaboratorioPDF
          sedeId={sedeId}
          indicadorId={indicadorId}
          sede={sede}
          institucion={institucion}
          periodo={periodo}
          gruposValues={grupos}
        />
      )
    case 'REPORT_PDF_GENERAL_INSTITUCION':
      return <GeneralInstitucionPDF institucion={institucion} />
    case 'REPORT_PDF_RESUMEN_INDICADOR':
      return (
        <ResumenIndicadorPDF
          indicadorId={indicadorId}
          desde={desde}
          hasta={hasta}
          institucion={institucion}
          sedesEncoded={sedesId}
        />
      )
    default:
      return <Error404 />
  }
}

export default GetExternalComponent
