import {MedicionIndicadorDTO} from '../../../../models/DTOs/Mediciones/MedicionIndicadorDTO'
import {ResponseDTO} from '../../../../models/DTOs/Mediciones/ResponseDTO'
import {UpdateMedicionDTO} from '../../../../models/DTOs/Mediciones/UpdateMedicionDTO'
import {MedicionModel} from '../../../../models/MedicionModel'
import {randomString} from '../../../../../_metronic/helpers/RandomString'

const MEDICIONES_KEY = 'MedicionesTableMock'

export class MedicionesTableMock {
  public static table: Array<MedicionModel> = []

  private static _table: Array<MedicionModel> = []

  private static save() {
    localStorage.setItem(MEDICIONES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const medicionesStorage = localStorage.getItem(MEDICIONES_KEY)
    this._table = medicionesStorage !== null ? JSON.parse(medicionesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearMediciones(sedeId: string, mediciones: MedicionIndicadorDTO[]) {
    let responses: ResponseDTO[] = []
    this.read()
    for (const medicion of mediciones) {
      this._table.push({
        Id: randomString(15),
        Fecha: medicion.Fecha,
        IndicadorId: medicion.IndicadorId,
        RequestId: medicion.RequestId,
        Observaciones: medicion.Observaciones,
        Valor: medicion.Valor,
        SedeId: sedeId,
      })

      responses.push({
        IsSuccess: medicion.Fecha !== '01/01/2023',
        RequestId: medicion.RequestId,
        Errors: [],
      })
    }
    this.save()
    return responses
  }

  static updateMedicion(
    sedeId: string,
    indicadorId: string,
    medicionId: string,
    updateMedicion: UpdateMedicionDTO
  ) {
    const medicion = this._table.find(
      (m) => m.IndicadorId === indicadorId && m.SedeId === sedeId && m.Id === medicionId
    )
    if (!medicion) return

    medicion.Fecha = updateMedicion.Fecha
    medicion.Observaciones = updateMedicion.Observaciones
    medicion.Valor = updateMedicion.Valor

    this.save()
  }
}
