import {FC, useEffect, useState} from 'react'
import {Button, Modal, Nav} from 'react-bootstrap'
import {KTSVG} from '../../helpers/components/KTSVG'
import {getSedesUsuario} from '../../../app/modules/auth/redux/CRUD/UsuariosSedesCRUD'
import {UsuarioSedesDTO} from '../../../app/models/DTOs/Sedes/UsuarioSedesDTO'
import {showError} from '../../helpers/AlertHelper'
import {nanoid} from '@reduxjs/toolkit'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import {RootState} from '../../../setup'
import {useIntl} from 'react-intl'
import {errorMessageBuilder} from '../../../app/modules/errors/error-message-builder'

const SedeSelect: FC = () => {
  const [showDlg, setShowDlg] = useState(false)
  const [sedes, setSedes] = useState<UsuarioSedesDTO[]>([])
  const sedeActual: UsuarioSedesDTO = useSelector<RootState>(
    ({auth}) => auth.sede,
    shallowEqual
  ) as UsuarioSedesDTO

  const intl = useIntl()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!showDlg) return
    getSedesUsuario()
      .then((r) => {
        setSedes(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.BRANCHES'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [showDlg])

  const sedeChangeHandler = () => {
    const sede = document.querySelector('#cbSedes') as HTMLSelectElement
    dispatch(auth.actions.setSede(sedes.find((s) => s.Id === sede.value)!))
    window.location.reload()
    setShowDlg(false)
  }

  const sedeSelectModalHandler = () => {
    setShowDlg(true)
  }

  return (
    <>
      <div>
        <Nav.Link onClick={sedeSelectModalHandler} className='btnSedes'>
          <span className='menu-icon mx-1'>
            <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-1' />
          </span>
          {sedeActual.Nombre}
        </Nav.Link>
      </div>

      <Modal show={showDlg}>
        <Modal.Header>
          <Modal.Title>{intl.formatMessage({id: 'HEADER.SELECT_BRANCH'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            <label>{intl.formatMessage({id: 'HEADER.BRANCHES'})}</label>
            <select className='form-select' id='cbSedes'>
              {sedes.map((sede) => {
                return (
                  sede.IsActivo && (
                    <option key={nanoid()} value={sede.Id} selected={sedeActual.Id === sede.Id}>
                      {sede.Nombre}
                    </option>
                  )
                )
              })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' name='btnCerrar' onClick={() => setShowDlg(false)}>
            {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
          </Button>
          <Button variant='primary' name='btnAceptar' onClick={sedeChangeHandler}>
            {intl.formatMessage({id: 'SETTINGS.ACCEPT'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SedeSelect
