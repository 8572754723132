import axios from 'axios'
import {UserModel} from '../../../../models/UserModel'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/usuarios/perfil`
export const UPDATE_PASSWORD = `${API_URL}/usuarios/perfil/password`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset`
export const VERIFY_HASH_RESET_PASSWORD = `${API_URL}/auth/reset/verify`
export const NEW_PASSWORD_RESET = `${API_URL}/auth/reset`
export const LOGOUT_URL = `${API_URL}/auth/logout`

export function updatePassword(oldPassword: string, newPassword: string) {
  return axios.post(UPDATE_PASSWORD, {OldPassword: oldPassword, NewPassword: newPassword})
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {Email: email, Password: password})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {}, {params: {email}})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getVerifyHashByURL(hash: string) {
  return axios.get(VERIFY_HASH_RESET_PASSWORD, {params: {hash}})
}

export function resetPassword(hash: string, password: string) {
  return axios.put(NEW_PASSWORD_RESET, {hash, password})
}

export function logout() {
  return axios.post(LOGOUT_URL)
}
