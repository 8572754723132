import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  estado: string
}

const TipoEventoAuditoriaTag: FC<Props> = ({estado}) => {
  const intl = useIntl()

  return estado === 'create' ? (
    <span className='badge badge-light-success'>
      {intl.formatMessage({id: 'AUDIT_EVENT.CREATE'})}
    </span>
  ) : estado === 'update' ? (
    <span className='badge badge-light-warning'>
      {intl.formatMessage({id: 'AUDIT_EVENT.UPDATE'})}
    </span>
  ) : estado === 'delete' ? (
    <span className='badge badge-light-danger'>
      {intl.formatMessage({id: 'AUDIT_EVENT.DELETE'})}
    </span>
  ) : (
    <span className='badge badge-light-dark'>{intl.formatMessage({id: 'AUDIT_EVENT.ERROR'})}</span>
  )
}

export {TipoEventoAuditoriaTag}
