import {nanoid} from '@reduxjs/toolkit'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {showError} from '../../../../../_metronic/helpers/AlertHelper'
import {CantidadMedicionesEstadoTag} from '../../../../../_metronic/helpers/components/CantidadMedicionesEstadoTag'
import {GeneralInstitucionDTO} from '../../../../models/DTOs/Reportes/GeneralInstitucionDTO'
import {getReporteGeneralInstitucion} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  institucion: string
}

const GeneralInstitucionPDF: FC<Props> = ({institucion}) => {
  const [reporte, setReporte] = useState<GeneralInstitucionDTO[]>([])

  const intl = useIntl()

  useEffect(() => {
    getReporteGeneralInstitucion()
      .then((r) => setReporte(r.data))
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  document.body.style.backgroundColor = '#FFF'

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='row'>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
          </label>
        </div>
        <div className='row mb-10 ml-2'>
          <span className='col-3 text-gray-500'>{institucion}</span>
        </div>
        <div className='alert alert-primary d-flex align-items-center'>
          <span className='svg-icon svg-icon-2hx svg-icon-primary me-4'>
            <KTSVG path='/media/icons/duotune/general/gen044.svg' />
          </span>
          <span>{intl.formatMessage({id: 'INFO.INSTITUTION_REPORT'})}</span>
        </div>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '10%', verticalAlign: 'middle'}} rowSpan={2}>
                  {intl.formatMessage({id: 'HEADER.CODE'})}
                </th>
                <th style={{width: '25%', verticalAlign: 'middle'}} rowSpan={2}>
                  {intl.formatMessage({id: 'HEADER.NAME'})}
                </th>
                <th style={{width: '15%', verticalAlign: 'middle'}} rowSpan={2}>
                  {intl.formatMessage({id: 'HEADER.PERIOD'})}
                </th>
                <th style={{textAlign: 'center'}} colSpan={4}>
                  {intl.formatMessage({id: 'HEADER.AMOUNT_BRANCHES'})}
                </th>
                <th style={{width: '10%', verticalAlign: 'middle'}} rowSpan={2}>
                  {intl.formatMessage({id: 'HEADER.ASSOSIATED_BRANCHES'})}
                </th>
              </tr>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.GREEN'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.YELLOW'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.RED'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.WITHOUT_REPORT'})}</th>
              </tr>
            </thead>
            <tbody>
              {reporte.length > 0 ? (
                reporte.map((r) => {
                  const periodo = r.Periodo.replace('.', ' - ')
                  return (
                    <tr key={nanoid()} data-codigo={r.Info.Codigo}>
                      <td>{r.Info.Codigo}</td>
                      <td>{r.Info.Nombre}</td>
                      <td>{periodo}</td>
                      <td>
                        <div style={{cursor: 'pointer'}} data-name='verde'>
                          <CantidadMedicionesEstadoTag estado='verde' cantidad={r.Sedes.Verde} />
                        </div>
                      </td>
                      <td>
                        <div style={{cursor: 'pointer'}} data-name='amarillo'>
                          <CantidadMedicionesEstadoTag
                            estado='amarillo'
                            cantidad={r.Sedes.Amarillo}
                          />
                        </div>
                      </td>
                      <td>
                        <div style={{cursor: 'pointer'}} data-name='rojo'>
                          <CantidadMedicionesEstadoTag estado='rojo' cantidad={r.Sedes.Rojo} />
                        </div>
                      </td>
                      <td>
                        <div style={{cursor: 'pointer'}} data-name='sin-reportar'>
                          <CantidadMedicionesEstadoTag
                            estado='sin-reportar'
                            cantidad={r.Sedes.SinReportar}
                          />
                        </div>
                      </td>
                      <td>{r.SedesAsociadas}</td>
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={8}>{intl.formatMessage({id: 'NOT_FOUND.DATA'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default GeneralInstitucionPDF
