import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  estado: string
  className?: string
}

const ProcesoTag: FC<Props> = ({estado, className}) => {
  const intl = useIntl()

  return estado === 'preanalitico' ? (
    <span className={`badge badge-light-dark ${className}`}>
      {intl.formatMessage({id: 'PROCESS.PREANALYTIC'})}
    </span>
  ) : estado === 'postanalitico' ? (
    <span className={`badge badge-light-dark ${className}`}>
      {intl.formatMessage({id: 'PROCESS.POSTANALYTIC'})}
    </span>
  ) : estado === 'analitico' ? (
    <span className={`badge badge-light-dark ${className}`}>
      {intl.formatMessage({id: 'PROCESS.ANALYTIC'})}
    </span>
  ) : (
    <span className={`badge badge-light-danger ${className}`}>
      {intl.formatMessage({id: 'PROCESS.ERROR'})}
    </span>
  )
}

export {ProcesoTag}
