import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {CreateTicketDTO} from '../../../../models/DTOs/Tickets/CreateTicketDTO'

export const LIST_TICKETS = `${API_URL}/soporte/tickets`
export const CREATE_TICKET = `${API_URL}/soporte/tickets`
export const GET_TICKET = `${API_URL}/soporte/tickets/:id`
export const ANSWER_TICKET = `${API_URL}/soporte/tickets/:id/respuestas`

export function getTickets() {
  return axios.get(LIST_TICKETS)
}

export function createTicket(nuevoTicket: CreateTicketDTO) {
  return axios.post(CREATE_TICKET, nuevoTicket)
}

export function getTicketDetails(ticketId: string) {
  return axios.get(GET_TICKET.replace(':id', ticketId))
}

export function enviarRespuesta(ticketId: string, mensaje: string, close: boolean) {
  return axios.post(ANSWER_TICKET.replace(':id', ticketId), {mensaje: mensaje, close})
}
