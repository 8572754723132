import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {UpdateInstitucionDTO} from '../../../../models/DTOs/Instituciones/UpdateInstitucionDTO'

export const GET_INSTITUCION = `${API_URL}/instituciones`
export const UPDATE_INSTITUTION = `${API_URL}/instituciones/:id`

export function getInstitucion() {
  return axios.get(GET_INSTITUCION)
}

export function updateInstitution(institucion: string, updateInstitucion: UpdateInstitucionDTO) {
  return axios.post(UPDATE_INSTITUTION.replace(':id', institucion), updateInstitucion)
}
