import {IntlShape} from 'react-intl'

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const toMinutesFromString = (time: string) => {
  const array = time.split('.')
  if (array.length === 2) {
    const dias = parseInt(array[0], 10)

    const array2 = array[1].split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60 + dias * 24 * 60
    return tiempo
  } else {
    const array2 = time.split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60
    return tiempo
  }
}

export const mesesHandler = (meses: number[], intl: IntlShape) => {
  return meses
    .map((mes) => {
      switch (mes) {
        case 1:
          return intl.formatMessage({id: 'MONTH.JANUARY'})
        case 2:
          return intl.formatMessage({id: 'MONTH.FEBRUARY'})
        case 3:
          return intl.formatMessage({id: 'MONTH.MARCH'})
        case 4:
          return intl.formatMessage({id: 'MONTH.APRIL'})
        case 5:
          return intl.formatMessage({id: 'MONTH.MAY'})
        case 6:
          return intl.formatMessage({id: 'MONTH.JUNE'})
        case 7:
          return intl.formatMessage({id: 'MONTH.JULY'})
        case 8:
          return intl.formatMessage({id: 'MONTH.AUGUST'})
        case 9:
          return intl.formatMessage({id: 'MONTH.SEPTEMBER'})
        case 10:
          return intl.formatMessage({id: 'MONTH.OCTOBER'})
        case 11:
          return intl.formatMessage({id: 'MONTH.NOVEMBER'})
        case 12:
          return intl.formatMessage({id: 'MONTH.DECEMBER'})
      }
    })
    .join(' - ')
}

export const tipoValorHandler = (tipo: string) => {
  switch (tipo) {
    case 'menor':
      return '<'
    case 'menor-igual':
      return '≤'
    case 'mayor':
      return '>'
    case 'mayor-igual':
      return '≥'
  }
}

export const tipoValorTextoHandler = (tipo: string, intl: IntlShape) => {
  switch (tipo) {
    case 'menor':
      return intl.formatMessage({id: 'LIMITVALUE.LESS'})
    case 'menor-igual':
      return intl.formatMessage({id: 'LIMITVALUE.LESSEQUAL'})
    case 'mayor':
      return intl.formatMessage({id: 'LIMITVALUE.GREATER'})
    case 'mayor-igual':
      return intl.formatMessage({id: 'LIMITVALUE.GREATEREQUAL'})
  }
}

export const procesoHandler = (proceso: string, intl: IntlShape) => {
  switch (proceso) {
    case 'preanalitico':
      return intl.formatMessage({id: 'PROCESS.PREANALYTIC'})
    case 'postanalitico':
      return intl.formatMessage({id: 'PROCESS.ANALYTIC'})
    case 'analitico':
      return intl.formatMessage({id: 'PROCESS.POSTANALYTIC'})
  }
}

export const servicioHandler = (servicio: string, intl: IntlShape) => {
  switch (servicio) {
    case 'laboratorio':
      return intl.formatMessage({id: 'SERVICE.LAB'})
    case 'banco-sangre':
      return intl.formatMessage({id: 'SERVICE.BLOODBANK'})
  }
}

export const formatoExpresionHandler = (formato: string, intl: IntlShape) => {
  switch (formato) {
    case 'numero':
      return intl.formatMessage({id: 'EXPRESSIONFORMAT.NUMBER'})
    case 'relacion':
      return intl.formatMessage({id: 'EXPRESSIONFORMAT.RELATION'})
    case 'sigma':
      return intl.formatMessage({id: 'EXPRESSIONFORMAT.SIGMA'})
    case 'percentil':
      return intl.formatMessage({id: 'EXPRESSIONFORMAT.PERCENTILE'})
    case 'porcentaje':
      return intl.formatMessage({id: 'EXPRESSIONFORMAT.PERCENT'})
  }
}

export const frecuenciaHandler = (frecuencia: string, intl: IntlShape) => {
  switch (frecuencia) {
    case 'diario':
      return intl.formatMessage({id: 'FREQUENCY.DAILY'})
    case 'semanal':
      return intl.formatMessage({id: 'FREQUENCY.WEEKLY'})
    case 'quincenal':
      return intl.formatMessage({id: 'FREQUENCY.BIWEEKLY'})
    case 'mensual':
      return intl.formatMessage({id: 'FREQUENCY.MONTHLY'})
    case 'otro':
      return intl.formatMessage({id: 'FREQUENCY.OTHER'})
  }
}

export const meses = (intl: IntlShape) => [
  {value: 1, label: intl.formatMessage({id: 'MONTH.JANUARY'})},
  {value: 2, label: intl.formatMessage({id: 'MONTH.FEBRUARY'})},
  {value: 3, label: intl.formatMessage({id: 'MONTH.MARCH'})},
  {value: 4, label: intl.formatMessage({id: 'MONTH.APRIL'})},
  {value: 5, label: intl.formatMessage({id: 'MONTH.MAY'})},
  {value: 6, label: intl.formatMessage({id: 'MONTH.JUNE'})},
  {value: 7, label: intl.formatMessage({id: 'MONTH.JULY'})},
  {value: 8, label: intl.formatMessage({id: 'MONTH.AUGUST'})},
  {value: 9, label: intl.formatMessage({id: 'MONTH.SEPTEMBER'})},
  {value: 10, label: intl.formatMessage({id: 'MONTH.OCTOBER'})},
  {value: 11, label: intl.formatMessage({id: 'MONTH.NOVEMBER'})},
  {value: 12, label: intl.formatMessage({id: 'MONTH.DECEMBER'})},
]
