import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {CreateSedeDTO} from '../../../../models/DTOs/Sedes/CreateSedeDTO'
import {UpdateSedeDTO} from '../../../../models/DTOs/Sedes/UpdateSedeDTO'
import {CreateSedeBatchDTO} from '../../../../models/DTOs/Sedes/CreateSedeBatchDTO'

export const GET_SEDES = `${API_URL}/sedes`
export const UPDATE_SEDE_STATUS = `${API_URL}/sedes/:id/status`
export const CREATE_SEDE = `${API_URL}/sedes`
export const UPDATE_SEDE = `${API_URL}/sedes/:id`
export const CREATE_SEDES = `${API_URL}/batch/sedes`

export function getSedes() {
  return axios.get(GET_SEDES)
}

export function updateSedeStatus(sedeId: string) {
  return axios.post(UPDATE_SEDE_STATUS.replace(':id', sedeId))
}

export function createSede(nuevaSede: CreateSedeDTO) {
  return axios.post(CREATE_SEDE, nuevaSede)
}

export function updateSede(sedeId: string, updateSede: UpdateSedeDTO) {
  return axios.post(UPDATE_SEDE.replace(':id', sedeId), updateSede)
}

export function createSedes(sedes: CreateSedeBatchDTO[]) {
  return axios.post(CREATE_SEDES, sedes)
}
