import moment from 'moment'
import {TicketModel} from '../../../../models/TicketModel'
import {CreateTicketDTO} from '../../../../models/DTOs/Tickets/CreateTicketDTO'
import {randomString} from '../../../../../_metronic/helpers/RandomString'

const TICKET_KEY = 'TicketsTableMock'

export class TicketsTableMock {
  public static table: Array<TicketModel> = [
    {
      Id: 'TEC-3i2NDaWmqGVd2S',
      Usuario: 'AdministradorLab',
      Asunto: 'No funciona el login cuando quiero iniciar sesion desde otra computadora',
      Departamento: 'soporte-tecnico',
      Fecha: '09-04-2023 12:00',
      UltActividad: '09-04-2023 12:00',
      Estado: 'cerrado',
      Mensaje: 'TWVuc2FqZSBkZSBwcnVlYmEg',
      Respuestas: [
        {
          Fecha: '12-04-2023 15:00',
          Mensaje: 'QnVlbm8sIHlhIHNvbHVjaW9uYW1vcyB0dSBwcm9ibGVtYQ==',
          Usuario: 'Valentin AAA',
          IsOwner: false,
        },
        {
          Fecha: '12-04-2024 15:30',
          Mensaje: 'TXVjaGFzIGdyYWNpYXMh',
          Usuario: 'AdministradorLab',
          IsOwner: true,
        },
      ],
    },
    {
      Id: 'ADM-3i2NDaWmqGVd2S',
      Usuario: 'AdministradorLab',
      Asunto: 'Error cuando cree el indicador',
      Departamento: 'admin',
      Fecha: '09-04-2023 12:00',
      UltActividad: '09-04-2023 12:00',
      Estado: 'en-proceso',
      Mensaje: 'TWVuc2FqZSBkZSBwcnVlYmEg',
      Respuestas: [
        {
          Fecha: '12-04-2023 15:00',
          Mensaje: 'TWUgb2x2aWRlIGRlIGFncmVnYXIgcXVlLi4u',
          Usuario: 'AdministradorLab',
          IsOwner: true,
        },
      ],
    },
  ]

  private static _table: Array<TicketModel> = []

  private static save() {
    localStorage.setItem(TICKET_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const ticketsStorage = localStorage.getItem(TICKET_KEY)
    this._table = ticketsStorage !== null ? JSON.parse(ticketsStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearTicket(ticket: CreateTicketDTO, usuario: string) {
    const newTicket: TicketModel = {
      Asunto: ticket.Asunto,
      Departamento: ticket.Departamento,
      Mensaje: ticket.Mensaje,
      Fecha: moment().format('DD-MM-YYYY HH:mm'),
      UltActividad: moment().format('DD-MM-YYYY HH:mm'),
      Id: (ticket.Departamento === 'admin' ? 'ADM' : 'TEC') + '-' + randomString(15),
      Estado: 'en-proceso',
      Usuario: usuario,
      Respuestas: [],
    }

    this.read()
    this._table.push(newTicket)
    this.save()
    return {...newTicket}
  }

  static addRespuesta(ticketId: string, mensaje: string, usuario: string, close: boolean) {
    const ticket = this._table.find((t) => t.Id === ticketId)

    if (ticket == null) return

    ticket.Respuestas.push({
      Fecha: moment().format('DD-MM-YYYY HH:mm'),
      Mensaje: mensaje,
      Usuario: usuario,
      IsOwner: ticket.Usuario === usuario,
    })

    if (close) {
      ticket.Estado = 'cerrado'
    }

    this.save()
  }
}
