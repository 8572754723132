import {FC} from 'react'

type Props = {
  estado: string
  cantidad: number
}

const CantidadMedicionesEstadoTag: FC<Props> = ({estado, cantidad}) => {
  return estado === 'verde' ? (
    <span className='badge badge-light-success'>{cantidad}</span>
  ) : estado === 'amarillo' ? (
    <span className='badge badge-light-warning'>{cantidad}</span>
  ) : estado === 'rojo' ? (
    <span className='badge badge-light-danger'>{cantidad}</span>
  ) : (
    <span className='badge badge-light-dark'>{cantidad}</span>
  )
}

export {CantidadMedicionesEstadoTag}
