import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../../modules/auth/redux/CRUD/AuthCRUD'
import {useIntl} from 'react-intl'

export function ForgotPassword() {
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const initialValues = {
    txtEmail: '',
  }

  const forgotPasswordSchema = Yup.object().shape({
    txtEmail: Yup.string()
      .email(intl.formatMessage({id: 'FORMIK.EMAIL'}))
      .min(3, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '3'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.txtEmail)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch((e) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'AUTH.FORGOT.DESC'})}
          </div>
          {/* end::Link */}
        </div>
        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_FIELD'})}
            </div>
          </div>
        )}
        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{intl.formatMessage({id: 'AUTH.FORGOT.SUCCESS'})}</div>
          </div>
        )}
        {/* end::Title */}
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          </label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('txtEmail')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.txtEmail && formik.errors.txtEmail},
              {
                'is-valid': formik.touched.txtEmail && !formik.errors.txtEmail,
              }
            )}
            type='email'
            autoComplete='off'
          />
          {formik.touched.txtEmail && formik.errors.txtEmail && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.txtEmail}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Link to='/auth/login'>
            <button
              type='button'
              name='btnCancelar'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_CANCEL'})}
            </button>
          </Link>{' '}
          <button
            type='submit'
            name='btnSubmit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_SEND'})}
            </span>
            {loading && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'WAITING'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
