import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {formatoExpresionHandler} from '../../../../../_metronic/helpers'
import {showInfo, showError} from '../../../../../_metronic/helpers/AlertHelper'
import {toUTCDate} from '../../../../../_metronic/helpers/FormatTime'
import {GraficoControlDTO} from '../../../../models/DTOs/Reportes/GraficoControlDTO'
import {getGraficoControl} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  sedeId: string
  indicadorId: string
  desde: string
  hasta: string
  sede: string
  institucion: string
}

const GraficosControlPDF: FC<Props> = ({desde, hasta, indicadorId, sedeId, institucion, sede}) => {
  const [options, setOptions] = useState({})
  const [reporte, setReporte] = useState<GraficoControlDTO>()

  const intl = useIntl()

  useEffect(() => {
    getGraficoControl(sedeId, indicadorId, desde, hasta)
      .then((r) => {
        if (!r.data?.Mediciones?.length) {
          showInfo(
            intl.formatMessage({id: 'NOT_FOUND.DATA'}),
            intl.formatMessage({id: 'NOT_FOUND.DATA_PERIOD'})
          )
          return
        }
        setReporte(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  const getInicioGrafico = (fechaSnapshot: string, fechaInicioPeriodo: string) => {
    if (moment(fechaSnapshot, 'DD/MM/YYYY') >= moment(fechaInicioPeriodo, 'DD/MM/YYYY')) {
      return toUTCDate(fechaSnapshot)
    } else {
      return toUTCDate(fechaInicioPeriodo)
    }
  }

  const getFinGrafico = (
    fechaFinPeriodo: string,
    fechasMediciones: string[],
    frecuencia: string
  ) => {
    let agregarDias = 0
    switch (frecuencia) {
      case 'diario':
        agregarDias = 1
        break
      case 'semanal':
        agregarDias = 7
        break
      case 'quincenal':
        agregarDias = 15
        break
      case 'mensual':
        agregarDias = 30
        break
      case 'otro':
        agregarDias = 30
        break
    }

    const maxFecha = moment.max(fechasMediciones.map((m) => moment(m, 'DD/MM/YYYY')))

    const fechaExtendida = moment(maxFecha, 'DD/MM/YYYY').add(agregarDias, 'd').format('DD/MM/YYYY')

    if (moment(fechaFinPeriodo, 'DD/MM/YYYY') <= moment(fechaExtendida, 'DD/MM/YYYY')) {
      return toUTCDate(fechaFinPeriodo)
    } else {
      return toUTCDate(fechaExtendida)
    }
  }

  useEffect(() => {
    if (reporte) {
      const inicioGrafico = getInicioGrafico(
        reporte.IndicadorSnapshots[0].Fecha,
        reporte.PeriodoDesde
      )
      const finGrafico = getFinGrafico(
        reporte.PeriodoHasta,
        reporte.Mediciones.flatMap((m) => m.Fecha),
        reporte.IndicadorSnapshots[reporte.IndicadorSnapshots.length - 1].FrecuenciaReporte
          .Frecuencia
      )
      const valores = [
        ...reporte.Mediciones.map((m) => {
          return m.Valor.Value
        }),
        ...reporte.IndicadorSnapshots.map((i) => {
          return [i.ValorLimite.Valor.Value, i.ValorObjetivo.Valor.Value]
        }).flat(),
      ]
      const valorMinimo = Math.min(...valores)
      const valorMaximo = Math.max(...valores)
      const diferenciaMinMax = valorMaximo - valorMinimo
      const valorMinimoGrafico = valorMinimo - diferenciaMinMax * 0.2
      const valorMaximoGrafico = valorMaximo + diferenciaMinMax * 0.2

      const series: any = [
        {
          name: intl.formatMessage({id: 'HEADER.TARGETVALUE'}),
          color: 'green',
          lineWidth: 3,
          lineColor: 'green',
          clickable: false,
          enableMouseTracking: false,
          marker: {
            radius: 0,
            enabled: false,
          },
          data: reporte.IndicadorSnapshots.map((indicador, index) => {
            return [
              {
                y: indicador.ValorObjetivo.Valor.Value,
                x: index === 0 ? inicioGrafico : toUTCDate(indicador.Fecha),
              },
              {
                y: indicador.ValorObjetivo.Valor.Value,
                x: reporte.IndicadorSnapshots[index + 1]
                  ? toUTCDate(reporte.IndicadorSnapshots[index + 1].Fecha)
                  : finGrafico,
              },
            ]
          }).flat(),
          events: {
            legendItemClick: function (e: any) {
              return false
            },
          },
        },
        {
          name: intl.formatMessage({id: 'HEADER.LIMITVALUE'}),
          color: 'red',
          lineWidth: 3,
          lineColor: 'red',
          clickable: false,
          enableMouseTracking: false,
          marker: {
            radius: 0,
            enabled: false,
          },
          data: reporte.IndicadorSnapshots.map((indicador, index) => {
            return [
              {
                y: indicador.ValorLimite.Valor.Value,
                x: index === 0 ? inicioGrafico : toUTCDate(indicador.Fecha),
              },
              {
                y: indicador.ValorLimite.Valor.Value,
                x: reporte.IndicadorSnapshots[index + 1]
                  ? toUTCDate(reporte.IndicadorSnapshots[index + 1].Fecha)
                  : finGrafico,
              },
            ]
          }).flat(),
          events: {
            legendItemClick: function (e: any) {
              return false
            },
          },
        },
      ]

      reporte.IndicadorSnapshots.slice(1).forEach((i, index) =>
        series.push({
          name: intl.formatMessage({id: 'HEADER.CHANGE_LIMIT_TARGET'}),
          color: '#212427',
          showInLegend: index === 0,
          lineWidth: 3,
          lineColor: '#212427',
          clickable: false,
          enableMouseTracking: false,
          marker: {
            radius: 0,
            enabled: false,
          },
          data: [
            {
              y: valorMinimoGrafico,
              x: toUTCDate(i.Fecha),
            },
            {
              y: valorMaximoGrafico,
              x: toUTCDate(i.Fecha),
            },
          ],
          events: {
            legendItemClick: function (e: any) {
              return false
            },
          },
        })
      )

      series.push({
        name: intl.formatMessage({id: 'HEADER.MEASUREMENTS'}),
        dashStyle: 'longdash',
        lineWidth: 1,
        lineColor: 'gray',
        color: 'gray',
        marker: {
          radius: 8,
        },
        tooltip: {
          headerFormat: null,
          pointFormat: `<b>${intl.formatMessage({
            id: 'HEADER.VALUE',
          })}</b>: {point.valor} <br/> <b>${intl.formatMessage({
            id: 'HEADER.DATE',
          })}</b>: {point.fecha}`,
        },
        data: reporte.Mediciones.map((medicion) => {
          const anio = +medicion.Fecha.split('/')[2]
          const mes = +medicion.Fecha.split('/')[1]
          const dia = +medicion.Fecha.split('/')[0]

          return {
            fecha: medicion.Fecha,
            valor: medicion.Valor.Value.toFixed(medicion.Valor.Precision),
            y: medicion.Valor.Value,
            x: Date.UTC(anio, mes - 1, dia),
            color:
              medicion.Estado === 'rojo'
                ? 'red'
                : medicion.Estado === 'amarillo'
                ? 'yellow'
                : medicion.Estado === 'verde'
                ? 'green'
                : 'gray',
          }
        }),
        events: {
          legendItemClick: function (e: any) {
            return false
          },
        },
      })

      setOptions({
        chart: {
          type: 'line',
          marginRight: 60,
          height: 500,
        },
        title: {
          text: intl.formatMessage({id: 'MENU.REPORT.CONTROLGRAPH'}),
        },
        subtitle: {
          align: 'center',
          useHTML: true,
          text: `<div style="text-align: center; margin-bottom: 30px;">${reporte.Indicador.Nombre}<br/>${reporte.PeriodoDesde} - ${reporte.PeriodoHasta}</div>`,
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: `${intl.formatMessage({id: 'HEADER.VALUE'})} (${formatoExpresionHandler(
              reporte.Indicador.FormatoExpresion,
              intl
            )})`,
          },
          max: valorMaximoGrafico,
          min: valorMinimoGrafico,
          endOnTick: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d/%m/%Y}',
            rotation: -45,
          },
          //TODO: pulir eje x
          //tickInterval: 1
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: false,
              },
            },
            marker: {
              symbol: 'circle',
            },
          },
        },
        series: series,
      })
    }
  }, [reporte])

  document.body.style.backgroundColor = '#FFF'

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='row'>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'MENU.TITLE.BRANCH'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.INDICATOR'})}</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_FROM'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_TO'})}
          </label>
        </div>
        <div className='row mb-10 ml-2'>
          <span className='col-3 text-gray-500'>{institucion}</span>
          <span className='col-2 text-gray-500'>{sede}</span>
          <span className='col-3 text-gray-500'>{reporte?.Indicador.Nombre}</span>
          <span className='col-2 text-gray-500'>{desde}</span>
          <span className='col-2 text-gray-500'>{hasta}</span>
        </div>
        <div id='graficoControl'>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </div>
  )
}

export default GraficosControlPDF
