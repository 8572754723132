import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {ResponseDTO} from '../../../../models/DTOs/Mediciones/ResponseDTO'
import {CreateSedeBatchDTO} from '../../../../models/DTOs/Sedes/CreateSedeBatchDTO'
import {CreateSedeDTO} from '../../../../models/DTOs/Sedes/CreateSedeDTO'
import {UpdateSedeDTO} from '../../../../models/DTOs/Sedes/UpdateSedeDTO'
import {SedesModel} from '../../../../models/HeadquarterModel'
import {PaisesTableMock} from './paisesTableMock'

const SEDES_KEY = 'SedesTableMock'

export class SedesTableMock {
  public static table: Array<SedesModel> = [
    {
      Id: 'sm2Ake7UIOpe231',
      Nombre: 'LaboratorioDemo',
      IsActivo: true,
      Pais: 'Argentina',
      Ciudad: 'Buenos Aires',
      Servicio: 'laboratorio',
      Institucion: 'LaboratorioDemo',
    },
    {
      Id: 'jrud393jfp90912',
      Nombre: 'BDS Vampiro',
      IsActivo: true,
      Pais: 'Mexico',
      Ciudad: 'Chihuahua',
      Servicio: 'banco-sangre',
      Institucion: 'LaboratorioDemo',
    },
    {
      Id: '3i2NDaWmqGVddm',
      Nombre: 'Sede 1',
      IsActivo: true,
      Pais: 'Argentina',
      Ciudad: 'Buenos Aires',
      Servicio: 'laboratorio',
      Institucion: 'LaboratorioDemo',
    },
  ]

  private static _table: Array<SedesModel> = []

  private static save() {
    localStorage.setItem(SEDES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const sedesStorage = localStorage.getItem(SEDES_KEY)
    this._table = sedesStorage !== null ? JSON.parse(sedesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static updateState(sedeId: string) {
    const sede = this._table.find((x) => x.Id === sedeId) as SedesModel
    if (sede === null) return

    sede.IsActivo = !sede.IsActivo

    this.save()
  }

  static crearSede(newSedeDTO: CreateSedeDTO): SedesModel {
    const newSede: SedesModel = {
      Id: randomString(15),
      IsActivo: true,
      Nombre: newSedeDTO.Nombre,
      Ciudad: newSedeDTO.Ciudad,
      Pais: PaisesTableMock.getTable().find((p) => p.Id === newSedeDTO.PaisId)!.Nombre,
      Servicio: newSedeDTO.Servicio,
      Institucion: newSedeDTO.Institucion,
    }
    this.read()
    this._table.push(newSede)
    this.save()
    return {...newSede}
  }

  static updateSede(sedeId: string, updateSede: UpdateSedeDTO) {
    const sede = this._table.find((x) => x.Id === sedeId) as SedesModel

    if (sede === null) return

    sede.Nombre = updateSede.Nombre
    sede.Ciudad = updateSede.Ciudad
    sede.Servicio = updateSede.Servicio
    sede.Pais = PaisesTableMock.getTable().find((p) => p.Id === updateSede.PaisId)!.Nombre

    this.save()
  }

  static crearSedes(institucion: string, sedes: CreateSedeBatchDTO[]) {
    let responses: ResponseDTO[] = []

    this.read()
    sedes.forEach((sede) => {
      const isSuccess = sede.Nombre !== 'Sede mala'

      responses.push({
        IsSuccess: isSuccess,
        RequestId: sede.RequestId,
        Errors: [],
      })

      if (isSuccess) {
        this._table.push({
          Id: randomString(15),
          Nombre: sede.Nombre,
          Pais: PaisesTableMock.getTable().find((p) => p.Id === sede.PaisId)!.Nombre,
          Ciudad: sede.Ciudad,
          Institucion: institucion,
          IsActivo: true,
          Servicio: sede.Servicio,
        })
      }
    })
    this.save()

    return responses
  }
}
