import {FC, useEffect, useState} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {ValueWithPrecisionDTO} from '../../../../models/DTOs/Indicadores/ValueWithPrecisionDTO'
import {useIntl} from 'react-intl'
import {formatoExpresionHandler} from '../../../../../_metronic/helpers'
import {LimiteDTO} from '../../../../models/DTOs/Indicadores/LimiteDTO'
import {Enumeracion} from '../../../../../_metronic/helpers/EnumeracionDTO'

type Props = {
  title: string
  indicador: {
    Codigo: string
    Nombre: string
    FormatoExpresion: string
    ValorObjetivo: LimiteDTO
    ValorLimite: LimiteDTO
  }
  valorInformado: ValueWithPrecisionDTO
  evaluacionGrupoValores: {
    Mediana?: ValueWithPrecisionDTO
    Percentil25?: ValueWithPrecisionDTO
    Percentil75?: ValueWithPrecisionDTO
    CantMediciones: number
    Estado?: Enumeracion
  }
}

const DesempenioGrupo: FC<Props> = ({title, valorInformado, evaluacionGrupoValores, indicador}) => {
  const [options, setOptions] = useState({})

  const intl = useIntl()

  const max = Math.max(
    valorInformado.Value,
    evaluacionGrupoValores.Mediana!.Value,
    evaluacionGrupoValores.Percentil25!.Value,
    evaluacionGrupoValores.Percentil75!.Value
  )
  const min = Math.min(
    0,
    valorInformado.Value,
    evaluacionGrupoValores.Mediana!.Value,
    evaluacionGrupoValores.Percentil25!.Value,
    evaluacionGrupoValores.Percentil75!.Value
  )

  const colorDesempenio =
    evaluacionGrupoValores.Estado?.Name === 'aceptable'
      ? '#1e9ef7'
      : evaluacionGrupoValores.Estado?.Name === 'excelente'
      ? '#67cd89'
      : '#ffc803'

  useEffect(() => {
    setOptions({
      chart: {
        type: 'column',
        marginRight: 60,
        height: 250,
        width: 350,
      },
      title: {
        text: `${title}`,
        style: {
          fontSize: '15px',
        },
      },
      subtitle: {
        align: 'center',
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.VALUE'})} (${formatoExpresionHandler(
            indicador.FormatoExpresion,
            intl
          )})`,
        },
        min: min,
        max: max,
        plotLines: [
          {
            color:
              indicador.ValorObjetivo.Tipo === 'menor' ||
              indicador.ValorObjetivo.Tipo === 'menor-igual'
                ? '#008000'
                : 'red',
            width: 3,
            value: evaluacionGrupoValores.Percentil25!.Value,
            zIndex: 5,
          },
          {
            color: '#C0FF80',
            width: 3,
            value: evaluacionGrupoValores.Mediana!.Value,
            zIndex: 5,
          },
          {
            color:
              indicador.ValorObjetivo.Tipo === 'menor' ||
              indicador.ValorObjetivo.Tipo === 'menor-igual'
                ? 'red'
                : '#008000',
            width: 3,
            value: evaluacionGrupoValores.Percentil75!.Value,
            zIndex: 5,
          },
        ],
      },
      xAxis: {
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        column: {
          pointWidth: 60,
          grouping: false,
          minPointLength: 3,
        },
        series: {
          groupPadding: 0.5,
          events: {
            legendItemClick: function () {
              return false
            },
          },
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
        },
        plotLines: {
          showInLegend: true,
        },
      },

      series: [
        {
          color:
            indicador.ValorObjetivo.Tipo === 'menor' ||
            indicador.ValorObjetivo.Tipo === 'menor-igual'
              ? '#008000'
              : 'red',
          name: 'P<sub>25</sub>',
        },
        {
          color: '#C0FF80',
          name: 'Mediana',
        },
        {
          color:
            indicador.ValorObjetivo.Tipo === 'menor' ||
            indicador.ValorObjetivo.Tipo === 'menor-igual'
              ? 'red'
              : '#008000',
          name: 'P<sub>75</sub>',
        },
        {
          type: 'column',
          name: intl.formatMessage({id: 'HEADER.PERFORMANCE'}),
          color: colorDesempenio,
          data: [
            {
              y: valorInformado.Value,
              x: 0,
              color: colorDesempenio,
            },
          ],
          tooltip: {
            headerFormat: null,
            pointFormat: `<b>${intl.formatMessage({id: 'HEADER.REPORTED_VALUE'})}</b>: {point.y}`,
          },
        },
      ],
    })
  }, [])

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default DesempenioGrupo
