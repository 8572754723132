import {FC} from 'react'
import {useIntl} from 'react-intl'
import DesempenioGrupo from '../../../app/pages/reportes/sede/interlaboratorio/DesempenioGrupo'
import EstadoDesempenioGrupoTag from './EstadoDesempenioGrupoTag'
import {KTSVG} from './KTSVG'
import {LimiteDTO} from '../../../app/models/DTOs/Indicadores/LimiteDTO'
import {ValueWithPrecisionDTO} from '../../../app/models/DTOs/Indicadores/ValueWithPrecisionDTO'
import {Enumeracion} from '../EnumeracionDTO'

type TablaProps = {
  indicador: {
    Codigo: string
    Nombre: string
    FormatoExpresion: string
    ValorObjetivo: LimiteDTO
    ValorLimite: LimiteDTO
  }
  grupoComparacion: {
    Periodo: string
    Mediana?: ValueWithPrecisionDTO
    Percentil25?: ValueWithPrecisionDTO
    Percentil75?: ValueWithPrecisionDTO
    CantMediciones: number
    Mediciones?: ValueWithPrecisionDTO[]
    Estado?: Enumeracion
    [x: string]: any
  }
  title: string
  titleGraph: string
  valorInformadoActual: ValueWithPrecisionDTO
  estado: string
}

const TablaEvaluacion: FC<TablaProps> = ({
  estado,
  grupoComparacion,
  indicador,
  title,
  titleGraph,
  valorInformadoActual,
}) => {
  const mediana = grupoComparacion.Mediana
  const percentil25 = grupoComparacion.Percentil25
  const percentil75 = grupoComparacion.Percentil75
  const estadoGrupo = grupoComparacion.Estado?.Name

  const intl = useIntl()

  return (
    <div className='row' style={{pageBreakInside: 'avoid'}}>
      {mediana?.Value !== undefined ? (
        <>
          <div className='col'>
            <label className='fw-bolder fs-3 mb-5'>{title}</label>
            <div className='row mb-3'>
              <div className='col fs-6 fw-bolder mb-1'>
                <span>{intl.formatMessage({id: 'HEADER.REPORTED_VALUE'})}:</span>
              </div>
              <div className='col fw-bold text-gray-600'>
                <span>{valorInformadoActual.Value.toFixed(valorInformadoActual.Precision)}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col fs-6 fw-bolder mb-1'>
                <span>{intl.formatMessage({id: 'HEADER.STATUS'})}:</span>
              </div>
              <div className='col fw-bold text-gray-600'>
                <span>
                  <EstadoDesempenioGrupoTag estado={estadoGrupo} />
                </span>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col fs-6 fw-bolder mb-1'>
                <span>{intl.formatMessage({id: 'HEADER.MEDIAN'})}:</span>
              </div>
              <div className='col fw-bold text-gray-600'>
                <span>{mediana.Value.toFixed(mediana.Precision)}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col fs-6 fw-bolder mb-1'>
                <span>
                  P<sub>25</sub>:
                </span>
              </div>
              <div className='col fw-bold text-gray-600'>
                <span>{percentil25!.Value.toFixed(percentil25!.Precision)}</span>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col fs-6 fw-bolder mb-1'>
                <span>
                  P<sub>75</sub>:
                </span>
              </div>
              <div className='col fw-bold text-gray-600'>
                <span>{percentil75!.Value.toFixed(percentil75!.Precision)}</span>
              </div>
            </div>
          </div>
          <div className='col'>
            <DesempenioGrupo
              title={titleGraph}
              indicador={indicador}
              valorInformado={valorInformadoActual}
              evaluacionGrupoValores={grupoComparacion}
            />
          </div>
        </>
      ) : (
        <>
          <label className='fw-bolder fs-3 mb-5'>{title}</label>
          <div className='alert alert-warning d-flex align-items-center'>
            <span className='svg-icon svg-icon-2hx svg-icon-warning me-4'>
              <KTSVG path='/media/icons/duotune/general/gen044.svg' />
            </span>
            <span>{intl.formatMessage({id: 'NOT_ENOUGH.DATA'})}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default TablaEvaluacion
