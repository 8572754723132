import {UsuariosSedesDTO} from '../../../../models/DTOs/Usuarios/UsuariosSedesDTO'

const USUARIOSSEDES_KEY = 'UsuariosSedesTableMock'

export class UsuariosSedesTableMock {
  public static table: Array<UsuariosSedesDTO> = [
    {
      UsuarioId: '1234567890abcde',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      UsuarioId: '1234567890abcde',
      SedeId: '3i2NDaWmqGVddm',
    },
    {
      UsuarioId: 'jdnduehsi3n5b6d',
      SedeId: 'sm2Ake7UIOpe231',
    },
    {
      UsuarioId: 'jdnduehsi3n5b6d',
      SedeId: 'jrud393jfp90912',
    },
  ]

  private static _table: Array<UsuariosSedesDTO> = []

  private static save() {
    localStorage.setItem(USUARIOSSEDES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const usuariosSedesStorage = localStorage.getItem(USUARIOSSEDES_KEY)
    this._table = usuariosSedesStorage !== null ? JSON.parse(usuariosSedesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearRelaciones(usuarioId: string, sedesId: string[]) {
    this.read()
    this._table = this._table.filter((r) => r.UsuarioId !== usuarioId)
    this.save()

    for (const sede of sedesId) {
      this.read()
      this._table.push({SedeId: sede, UsuarioId: usuarioId})
      this.save()
    }
    return
  }

  static crearRelacionesMasivas(relaciones: {SedeId: string; UsuarioId: string}[]) {
    this.read()
    this._table = []
    this.save()

    for (const relacion of relaciones) {
      this.read()
      this._table.push({SedeId: relacion.SedeId, UsuarioId: relacion.UsuarioId})
      this.save()
    }
  }
}
