import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  getRespuestasPruebaPilotoStatus,
  listPreguntasPruebaPiloto,
  sendRespuestasPruebaPiloto,
} from '../../modules/auth/redux/CRUD/PruebaPilotoCRUD'
import {showError, showSuccess, toastSuccess} from '../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../modules/errors/error-message-builder'
import PruebaDeshabilitada from './PruebaDeshabilitada'
import {FallbackView} from '../../../_metronic/partials'

const PruebaPreguntas: FC = () => {
  const [respuestasStatus, setRespuestasStatus] = useState<boolean | undefined>(undefined)
  const [preguntas, setPreguntas] = useState<
    {Id: string; Descripcion: string; ValoresPosibles: string[]; Orden: number}[]
  >([])
  const [answers, setAnswers] = useState<{PreguntaId: string; Valor: string}[]>([])
  const [tyc, setTyc] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    getRespuestasPruebaPilotoStatus()
      .then((r) => setRespuestasStatus(r.data))
      .catch((e) =>
        showError(
          'Error al cargar las respuestas',
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  useEffect(() => {
    if (respuestasStatus === false)
      listPreguntasPruebaPiloto()
        .then((r) => {
          setPreguntas(r.data)
        })
        .catch((e) =>
          showError(
            'Error al cargar las respuestas',
            errorMessageBuilder(e, intl.formatMessage),
            intl.formatMessage({id: 'SETTINGS.ACCEPT'})
          )
        )
  }, [respuestasStatus])

  const handleOptionChange = (
    index: number,
    preguntaId: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAnswers = [...answers]
    newAnswers[index] = {PreguntaId: preguntaId, Valor: event.target.value}
    setAnswers(newAnswers)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    sendRespuestasPruebaPiloto(answers)
      .then(() => {
        showSuccess(
          'Operación completada',
          'Se han enviado las respuestas correctamente',
          'Aceptar'
        ).then((r) => {
          if (r.isConfirmed) window.location.reload()
        })
      })
      .catch((e) =>
        showError(
          'Error al enviar las respuestas',
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }

  return (
    <>
      {respuestasStatus === undefined && <FallbackView />}
      {respuestasStatus === true && <PruebaDeshabilitada />}
      {respuestasStatus === false && (
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
            <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-10 align-items-center'>
              <a href='/dashboard' className='mb-10 pt-lg-1'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logo_gm_qi.png')}
                  className='h-150px'
                />
              </a>
              <div
                style={{margin: '10px 100px 10px 100px'}}
                className='alert alert-primary d-flex align-items-center p-5 mb-3 fs-2'
              >
                <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                  <KTSVG path='/media/icons/duotune/general/gen044.svg' />
                </span>
                <span>
                  <b>Importante: </b>Si el laboratorio posee más de una sucursal, responder las
                  preguntas considerando sólo la “sede central”
                </span>
              </div>
              <div className='fw-bold fs-1 text-gray-600 my-10'>Encuesta Inicial</div>
              <div className='d-flex w-75 text-left'>
                <form onSubmit={handleSubmit}>
                  {preguntas
                    .sort((a, b) => a.Orden - b.Orden)
                    .map((pregunta, index) => {
                      return (
                        <Question
                          key={`pregunta_${pregunta.Id}`}
                          question={pregunta.Descripcion}
                          questionId={pregunta.Id}
                          options={pregunta.ValoresPosibles}
                          onChange={(event) => handleOptionChange(index, pregunta.Id, event)}
                        />
                      )
                    })}
                  <hr />
                  <div className='form-check my-10'>
                    <input
                      type='checkbox'
                      id={`terminos_condiciones`}
                      name={`terminos_condiciones`}
                      checked={tyc}
                      onChange={() => setTyc(!tyc)}
                      className='form-check-input'
                    />
                    <label htmlFor={`terminos_condiciones`} className='form-check-label fs-3'>
                      Acepto los{' '}
                      <a
                        href='https://resources.gmigliarino.com/gmonitor-qi/tyc.pdf'
                        target='_blank'
                      >
                        Términos y Condiciones
                      </a>
                    </label>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={!tyc || answers.filter((a) => a?.Valor != null).length < 6}
                  >
                    Enviar respuestas
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

interface QuestionProps {
  question: string
  questionId: string
  options: string[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Question: FC<QuestionProps> = ({question, options, onChange, questionId}) => {
  return (
    <div className='mb-8'>
      <h2 className='mb-4'>{question}</h2>
      <div>
        {options.map((option, index) => (
          <div key={index} className='form-check mb-4'>
            <input
              type='radio'
              id={`preg_${questionId}_option_${index}`}
              name={`preg_${questionId}`}
              value={option}
              onChange={onChange}
              className='form-check-input'
            />
            <label htmlFor={`preg_${questionId}_option${index}`} className='form-check-label fs-3'>
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PruebaPreguntas
