import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {CreateUsuarioDTO} from '../../../../models/DTOs/Usuarios/CreateUsuarioDTO'
import {UserModel} from '../../../../models/UserModel'
import {CreateUsuarioBatchDTO} from '../../../../models/DTOs/Usuarios/CreateUsuarioBatchDTO'
import {ResponseDTO} from '../../../../models/DTOs/Mediciones/ResponseDTO'
const USUARIO_KEY = 'UsuariosTableMock'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      Id: '1234567890abcde',
      Email: 'adminclient@demo.com',
      Rol: 'admin', // Administrator
      Nombre: 'AdministradorLab',
      IsActivo: true,
      Institucion: 'LaboratorioDemo',
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      Idioma: 'español',
    },
    {
      Id: 'jdnduehsi3n5b6d',
      Email: 'userclient@demo.com',
      Rol: 'general', // Usuario
      Nombre: 'General',
      IsActivo: true,
      Institucion: 'LaboratorioDemo',
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5ca',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      Idioma: 'español',
    },
    {
      Id: 'oaj39578mdnsjdi',
      Email: 'dylanperro@demo.com',
      Rol: 'general', // Usuario
      Nombre: 'Dylan Perro',
      IsActivo: false,
      Institucion: 'LaboratorioDemo',
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cb',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      Idioma: 'español',
    },
  ]

  private static _table: Array<UserModel> = []

  private static save() {
    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const asistenciaStorage = localStorage.getItem(USUARIO_KEY)
    this._table = asistenciaStorage !== null ? JSON.parse(asistenciaStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearUsuario(newUserDTO: CreateUsuarioDTO): UserModel {
    const newUser: UserModel = {
      Id: randomString(15),
      IsActivo: true,
      Email: newUserDTO.Email,
      Nombre: newUserDTO.Nombre,
      Rol: newUserDTO.Rol,
      Institucion: newUserDTO.Laboratorio,
      Auth: {
        AccessToken: 'access-token-' + randomString(32),
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      Idioma: newUserDTO.Idioma,
    }
    this.read()
    this._table.push(newUser)
    this.save()
    return {...newUser}
  }

  static updateState(usuarioId: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel

    if (usuario === null) return

    usuario.IsActivo = !usuario.IsActivo

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateUser(
    userId: string,
    userNombre: string,
    userRol: string,
    userEmail: string,
    userIdioma: string
  ) {
    const usuario = this._table.find((x) => x.Id === userId) as UserModel
    if (!usuario) return

    usuario.Nombre = userNombre
    usuario.Email = userEmail
    usuario.Rol = userRol
    usuario.Idioma = userIdioma

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static crearUsuarios(institucion: string, usuarios: CreateUsuarioBatchDTO[]) {
    let responses: ResponseDTO[] = []

    this.read()
    usuarios.forEach((usuario) => {
      const isSuccess = usuario.Nombre !== 'Usuario malo'

      responses.push({
        IsSuccess: isSuccess,
        RequestId: usuario.RequestId,
        Errors: [],
      })

      if (isSuccess) {
        this._table.push({
          Id: randomString(15),
          Nombre: usuario.Nombre,
          Email: usuario.Email,
          Rol: usuario.Rol,
          Idioma: usuario.Idioma,
          Auth: {
            AccessToken: randomString(30),
            RefreshToken: randomString(30),
          },
          IsActivo: true,
          Institucion: institucion,
        })
      }
    })
    this.save()

    return responses
  }
}
