import {nanoid} from '@reduxjs/toolkit'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {TablaResumenIndicadorDTO} from '../../../../models/DTOs/Reportes/TablaResumenIndicadorDTO'
import {getResumenIndicador} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {showError} from '../../../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  institucion: string
  desde: string
  hasta: string
  indicadorId: string
  sedesEncoded: string[]
}

const ResumenIndicadorPDF: FC<Props> = ({desde, hasta, indicadorId, institucion, sedesEncoded}) => {
  const [reporte, setReporte] = useState<TablaResumenIndicadorDTO>()

  const intl = useIntl()

  useEffect(() => {
    getResumenIndicador(indicadorId, sedesEncoded, desde, hasta)
      .then((r) => {
        setReporte(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  document.body.style.backgroundColor = '#FFF'

  return (
    <div className='card my-5 mb-xl-8 pb-3'>
      <div className='card-body py-3 pt-8'>
        <div className='row'>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
          </label>

          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.INDICATOR'})}</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_FROM'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_TO'})}
          </label>
        </div>
        <div className='row mb-10 ml-2'>
          <span className='col-3 text-gray-500'>{institucion}</span>
          <span className='col-3 text-gray-500'>{reporte?.Info.Nombre}</span>
          <span className='col-2 text-gray-500'>{desde}</span>
          <span className='col-2 text-gray-500'>{hasta}</span>
        </div>
        {reporte ? (
          <>
            {reporte.PeriodosMediciones.length > 7 && (
              <div className='alert alert-primary d-flex align-items-center'>
                <span className='svg-icon svg-icon-2hx svg-icon-primary me-4'>
                  <KTSVG path='/media/icons/duotune/general/gen044.svg' />
                </span>
                <span>
                  {intl
                    .formatMessage({id: 'INFO.INDICATOR_SUMMARY'})
                    .replace('{length}', reporte.PeriodosMediciones.length.toString())}
                </span>
              </div>
            )}

            <div className='table-responsive' id='tablaSeguimiento'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th style={{width: '20%'}}>{intl.formatMessage({id: 'HEADER.BRANCHES'})}</th>
                    {reporte.PeriodosMediciones.slice(-7).map((periodo, index) => {
                      if (index < 7) {
                        return <th key={nanoid()}>{periodo}</th>
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {reporte.Sedes.map((sede) => {
                    return (
                      <tr key={nanoid()}>
                        <td>{sede.Nombre}</td>
                        {reporte.PeriodosMediciones.slice(-7).map((periodo, index) => {
                          if (index < 7) {
                            const medicion = sede.Mediciones.find((m) => m.Fecha === periodo)

                            let colorBullet = ''
                            if (medicion?.Estado === 'rojo') colorBullet = 'red'
                            else if (medicion?.Estado === 'amarillo') colorBullet = 'yellow'
                            else if (medicion?.Estado === 'verde') colorBullet = 'green'

                            return (
                              <td key={nanoid()} style={{cursor: medicion?.Id && 'pointer'}}>
                                <span
                                  className='bullet h-10px w-10px mx-1'
                                  title='Carga fuera de término'
                                  style={{background: colorBullet}}
                                />
                                {medicion?.Valor.Value}
                              </td>
                            )
                          }
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <p className='text-center'>{intl.formatMessage({id: 'NOT_FOUND.DATA_PERIOD'})}</p>
        )}
      </div>
    </div>
  )
}

export default ResumenIndicadorPDF
