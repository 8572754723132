import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const CREATE_USUARIO_SEDES = `${API_URL}/usuarios/:id/sedes`
export const CREATE_USUARIOS_SEDES = `${API_URL}/batch/usuarios/sedes`
export const GET_RELACIONES = `${API_URL}/usuarios/:id/sedes`
export const GET_SEDES_USUARIO = `${API_URL}/usuario/sedes`
export const GET_SEDES_USUARIOS = `${API_URL}/usuarios/sedes`

export function createUsuarioSedes(usuarioId: string, sedesId: string[]) {
  return axios.post(CREATE_USUARIO_SEDES.replace(':id', usuarioId), sedesId)
}

export function createUsuariosSedes(relaciones: {SedeId: string; UsuarioId: string}[]) {
  return axios.post(CREATE_USUARIOS_SEDES, relaciones)
}

export function getRelacionesUsuarioSedes(usuarioId: string) {
  return axios.get(GET_RELACIONES.replace(':id', usuarioId))
}

export function getSedesUsuario() {
  return axios.get(GET_SEDES_USUARIO)
}

export function getRelacionesUsuariosSedes() {
  return axios.get(GET_SEDES_USUARIOS)
}
