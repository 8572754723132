import {nanoid} from '@reduxjs/toolkit'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {tipoValorHandler} from '../../../../../_metronic/helpers'
import BulletComponent from '../../../../../_metronic/helpers/components/BulletComponent'
import {TablaSeguimientoDTO} from '../../../../models/DTOs/Reportes/TablaSeguimientoDTO'
import {getTablaSeguimiento} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {showError} from '../../../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  sedeId: string
  indicadorId: string
  desde: string
  hasta: string
  sede: string
  institucion: string
}

const TablaSeguimientoPDF: FC<Props> = ({desde, hasta, indicadorId, institucion, sede, sedeId}) => {
  const [reporte, setReporte] = useState<TablaSeguimientoDTO>()

  const intl = useIntl()

  useEffect(() => {
    getTablaSeguimiento(sedeId, indicadorId, desde, hasta)
      .then((r) => {
        setReporte(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  document.body.style.backgroundColor = '#FFF'

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='row'>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'MENU.TITLE.BRANCH'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.INDICATOR'})}</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_FROM'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_TO'})}
          </label>
        </div>
        <div className='row mb-10 ml-2'>
          <span className='col-3 text-gray-500'>{institucion}</span>
          <span className='col-2 text-gray-500'>{sede}</span>
          <span className='col-3 text-gray-500'>{reporte?.Indicador.Nombre}</span>
          <span className='col-2 text-gray-500'>{desde}</span>
          <span className='col-2 text-gray-500'>{hasta}</span>
        </div>
        <div className='row mb-10'>
          <div className='col-2 fs-6 fw-bolder mb-1'>
            <span>{intl.formatMessage({id: 'HEADER.NAME'})}:</span>
          </div>
          <div className='col fw-bold text-gray-600 txtNombre'>
            <span>{reporte?.Indicador.Nombre}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-2 fs-6 fw-bolder mb-1'>
            <span>{intl.formatMessage({id: 'HEADER.CODE'})}:</span>
          </div>
          <div className='col fw-bold text-gray-600 txtCodigo'>
            <span>{reporte?.Indicador.Codigo}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-2 fs-6 fw-bolder mb-1'>
            <span>{intl.formatMessage({id: 'HEADER.EVALUATED_PERIOD'})}:</span>
          </div>
          <div className='col fw-bold text-gray-600 txtPeriodo'>
            <span>{`${reporte?.PeriodoDesde} - ${reporte?.PeriodoHasta}`}</span>
          </div>
        </div>
        <div className='table-responsive' id='tablaSeguimiento'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.DATE'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.TARGETVALUE'})}</th>
                <th style={{width: '8%'}}>{intl.formatMessage({id: 'HEADER.LIMITVALUE'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.VALUE'})}</th>
                <th style={{width: '10%'}}>{intl.formatMessage({id: 'HEADER.STATUS'})}</th>
                <th style={{width: '52%'}}>{intl.formatMessage({id: 'HEADER.OBSERVATIONS'})}</th>
              </tr>
            </thead>
            <tbody>
              {reporte?.Mediciones.length ? (
                reporte.Mediciones.map((medicion) => {
                  return (
                    <tr key={nanoid()}>
                      <td>{medicion.Fecha}</td>
                      <td>{`${tipoValorHandler(
                        medicion.ValorObjetivo.Tipo
                      )} ${medicion.ValorObjetivo.Valor.Value.toFixed(
                        medicion.ValorObjetivo.Valor.Precision
                      )}`}</td>
                      <td>{`${tipoValorHandler(
                        medicion.ValorLimite.Tipo
                      )} ${medicion.ValorLimite.Valor.Value.toFixed(
                        medicion.ValorLimite.Valor.Precision
                      )}`}</td>
                      <td>{medicion.Valor.Value.toFixed(medicion.Valor.Precision)}</td>
                      <td>
                        <BulletComponent color={medicion.Estado} />
                      </td>
                      <td>{medicion.Observaciones}</td>
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center mb-5'>
                  <td colSpan={8}>{intl.formatMessage({id: 'NOT_FOUND.MEASUREMENTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TablaSeguimientoPDF
