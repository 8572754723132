import {FC, useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../models/UserModel'
import {IndicadorPendienteCargaDTO} from '../../models/DTOs/Indicadores/IndicadorPendienteCargaDTO'
import {nanoid} from '@reduxjs/toolkit'
import {getIndicadoresPendientes} from '../../modules/auth/redux/CRUD/ReportesCRUD'
import {UsuarioSedesDTO} from '../../models/DTOs/Sedes/UsuarioSedesDTO'
import {showError} from '../../../_metronic/helpers/AlertHelper'
import {ProcesoTag} from '../../../_metronic/helpers/components/ProcesoTag'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {errorMessageBuilder} from '../../modules/errors/error-message-builder'

const DashboardWrapper: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const sedeActual: UsuarioSedesDTO = useSelector<RootState>(
    ({auth}) => auth.sede,
    shallowEqual
  ) as UsuarioSedesDTO
  const [indicadoresPendientes, setIndicadoresPendientes] = useState<IndicadorPendienteCargaDTO>()

  const intl = useIntl()

  useEffect(() => {
    getIndicadoresPendientes(sedeActual.Id)
      .then((r) => {
        setIndicadoresPendientes(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.INDICATORS'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  return (
    <>
      <PageTitle>{`${intl.formatMessage({id: 'GREETING'})} ${user.Nombre}!`}</PageTitle>

      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <div className='card-title align-items-start col-7'>
            {intl.formatMessage({id: 'MENU.DASHBOARD.PENDING'})}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th style={{width: '15%'}}>{intl.formatMessage({id: 'HEADER.CODE'})}</th>
                  <th style={{width: '40%'}}>{intl.formatMessage({id: 'HEADER.NAME'})}</th>
                  <th style={{width: '25%'}}>{intl.formatMessage({id: 'HEADER.PROCESS'})}</th>
                  <th style={{width: '20%'}}>{intl.formatMessage({id: 'HEADER.LIMITDATE'})}</th>
                </tr>
              </thead>
              <tbody>
                {indicadoresPendientes?.Indicadores.length ? (
                  indicadoresPendientes.Indicadores.map((indicador) => {
                    return (
                      <tr key={nanoid()}>
                        <td>{indicador.Codigo}</td>
                        <td>{indicador.Nombre}</td>
                        <td>
                          <ProcesoTag estado={indicador.Proceso} />
                        </td>
                        <td>{indicador.FechaCargaLimite}</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr className='text-center mb-5'>
                    <td colSpan={8}>{intl.formatMessage({id: 'NOT_FOUND.PENDING'})}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
