import {SedeIndicadoresDTO} from '../../../../models/DTOs/Sedes/SedeIndicadoresDTO'

const SEDESINDICADORES_KEY = 'SedesIndicadoresTableMock'

export class SedesIndicadoresTableMock {
  public static table: Array<SedeIndicadoresDTO> = []

  private static _table: Array<SedeIndicadoresDTO> = []

  private static save() {
    localStorage.setItem(SEDESINDICADORES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const sedesIndicadoresStorage = localStorage.getItem(SEDESINDICADORES_KEY)
    this._table = sedesIndicadoresStorage !== null ? JSON.parse(sedesIndicadoresStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearRelaciones(sedeId: string, indicadoresId: string[]) {
    this.read()
    this._table = this._table.filter((r) => r.SedeId !== sedeId)
    this.save()

    for (const indicador of indicadoresId) {
      this.read()
      this._table.push({IndicadorId: indicador, SedeId: sedeId})
      this.save()
    }
  }

  static crearRelacionesMasivas(relaciones: {SedeId: string; IndicadorId: string}[]) {
    this.read()
    this._table = []
    this.save()

    for (const relacion of relaciones) {
      this.read()
      this._table.push({SedeId: relacion.SedeId, IndicadorId: relacion.IndicadorId})
      this.save()
    }
  }
}
