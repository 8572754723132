import {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Logout} from '../modules/auth'

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))
  const UsersList = lazy(() => import('../pages/users-list/UsersList'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const SedesList = lazy(() => import('../pages/sedes-list/SedesList'))
  const Institution = lazy(() => import('../pages/institution/Institution'))
  const IndicadoresList = lazy(() => import('../pages/indicadores/IndicadoresList'))
  const SedeIndicadores = lazy(() => import('../pages/sede-indicadores/SedeIndicadores'))
  const GraficoControl = lazy(() => import('../pages/reportes/sede/GraficoControl'))
  const TablaSeguimiento = lazy(() => import('../pages/reportes/sede/TablaSeguimiento'))
  const Auditoria = lazy(() => import('../pages/reportes/sede/Auditoria'))
  const GeneralInstitucion = lazy(() => import('../pages/reportes/institucion/GeneralInstitucion'))
  const TablaResumenIndicador = lazy(
    () => import('../pages/reportes/institucion/TablaResumenIndicador')
  )
  const Interlaboratorio = lazy(
    () => import('../pages/reportes/sede/interlaboratorio/Interlaboratorio')
  )

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const isAdmin = user.Rol === 'admin'

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/support/tickets' component={Tickets} />
        {isAdmin && <Route path='/userslist' component={UsersList} />}
        {isAdmin && <Route path='/sedeslist' component={SedesList} />}
        {isAdmin && <Route path='/institution' component={Institution} />}
        {isAdmin && <Route path='/indicators' component={IndicadoresList} />}
        <Route path='/account' component={AccountPage} />
        <Route path='/sede/indicadores' component={SedeIndicadores} />
        <Route path='/individualreports/controlgraph' component={GraficoControl} />
        <Route path='/individualreports/tranckingtable' component={TablaSeguimiento} />
        <Route path='/individualreports/audit' component={Auditoria} />
        <Route path='/individualreports/interlab' component={Interlaboratorio} />
        {isAdmin && (
          <Route path='/generalreports/indicatortablesummary' component={TablaResumenIndicador} />
        )}
        {isAdmin && (
          <Route path='/generalreports/reportinstitution' component={GeneralInstitucion} />
        )}
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
