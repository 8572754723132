import {nanoid} from '@reduxjs/toolkit'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {TipoEventoAuditoriaTag} from '../../../../../_metronic/helpers/components/TipoEventoAuditoriaTag'
import {AuditoriaDTO} from '../../../../models/DTOs/Reportes/AuditoriaDTO'
import {getAuditoria} from '../../../../modules/auth/redux/CRUD/ReportesCRUD'
import {showError} from '../../../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../../../modules/errors/error-message-builder'

interface Props {
  sedeId: string
  indicadorId: string
  desde: string
  hasta: string
  sede: string
  institucion: string
}

const AuditoriaPDF: FC<Props> = ({desde, hasta, indicadorId, institucion, sede, sedeId}) => {
  const [reporte, setReporte] = useState<AuditoriaDTO>()

  const intl = useIntl()

  useEffect(() => {
    getAuditoria(sedeId, indicadorId, desde, hasta)
      .then((r) => {
        setReporte(r.data)
      })
      .catch((e) =>
        showError(
          intl.formatMessage({id: 'ERROR.LOADING.REPORT'}),
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  document.body.style.backgroundColor = '#FFF'

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='row'>
          <label className='form-label col-3'>
            {intl.formatMessage({id: 'HEADER.INSTITUTION'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'MENU.TITLE.BRANCH'})}
          </label>
          <label className='form-label col-3'>{intl.formatMessage({id: 'HEADER.INDICATOR'})}</label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_FROM'})}
          </label>
          <label className='form-label col-2'>
            {intl.formatMessage({id: 'PLACEHOLDER.PERIOD_TO'})}
          </label>
        </div>
        <div className='row mb-10 ml-2'>
          <span className='col-3 text-gray-500'>{institucion}</span>
          <span className='col-2 text-gray-500'>{sede}</span>
          <span className='col-3 text-gray-500'>{reporte?.Indicador.Nombre}</span>
          <span className='col-2 text-gray-500'>{desde}</span>
          <span className='col-2 text-gray-500'>{hasta}</span>
        </div>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th style={{width: '15%'}}>{intl.formatMessage({id: 'HEADER.DATETIME'})}</th>

                <th style={{width: '60%'}}>{intl.formatMessage({id: 'HEADER.ACTION'})}</th>
                <th style={{width: '25%'}}>{intl.formatMessage({id: 'HEADER.RESPONSIBLE'})}</th>
              </tr>
            </thead>
            <tbody>
              {reporte?.Eventos.map((evento) => {
                return (
                  <tr key={nanoid()}>
                    <td>{evento.Fecha}</td>
                    <td>
                      <TipoEventoAuditoriaTag estado={evento.Tipo} /> {evento.Descripcion}
                    </td>
                    <td>{evento.Responsable}</td>
                  </tr>
                )
              })}
              {reporte?.Eventos.length === 0 && (
                <tr className='text-center mb-5'>
                  <td colSpan={8}>{intl.formatMessage({id: 'NOT_FOUND.EVENTS'})}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AuditoriaPDF
