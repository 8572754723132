import {parseAndFormatError} from './error-message-parser'

const errorMessageBuilder = (error: any, formatMessageFn: any) => {
  let mensaje = ''
  let errores = []

  if (error.response?.data) {
    mensaje = error.response.data.message || ''
    errores = error.response.data.errors || []

    let template = ''

    if (!mensaje && errores.length === 0) return parseAndFormatError(error, formatMessageFn)
    if (mensaje) template += parseAndFormatError(mensaje, formatMessageFn)
    if (mensaje && errores.length) template += '<br/>'
    if (errores.length)
      template +=
        errores.length > 1
          ? errores
              .map((e: any) => {
                return `- ${parseAndFormatError(e, formatMessageFn)}`
              })
              .join('<br/>')
          : parseAndFormatError(errores[0], formatMessageFn)

    return template
  }

  if (error.Errors?.length > 0) {
    errores = error.Errors || []

    let template = ''

    if (errores.length)
      template +=
        errores.length > 1
          ? errores
              .map((e: any) => {
                return `- ${parseAndFormatError(e, formatMessageFn)}`
              })
              .join('<br/>')
          : parseAndFormatError(errores[0], formatMessageFn)

    return template
  }

  return parseAndFormatError(error, formatMessageFn)
}

export {errorMessageBuilder}
