import {FC, useContext, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import ModalContext from './ModelContext'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {CreateTicketDTO} from '../../../../app/models/DTOs/Tickets/CreateTicketDTO'
import {createTicket} from '../../../../app/modules/auth/redux/CRUD/TicketsCRUD'
import {showError, showWarning, toastSuccess} from '../../../helpers/AlertHelper'
import {KTSVG} from '../../../helpers'
import {useIntl} from 'react-intl'
import {errorMessageBuilder} from '../../../../app/modules/errors/error-message-builder'

export const ON_TICKET_SUBMIT = 'onTicketSubmit'

const NuevoTicket: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const {isOpen, toggleModal} = useContext(ModalContext)

  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  const newTicketSchema = Yup.object().shape({
    txtAsunto: Yup.string()
      .min(5, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '5'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    txtMensaje: Yup.string()
      .min(10, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '10'))
      .max(5000, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '5000'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    cbDepto: Yup.string().required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
  })

  const initialValues = {
    txtAsunto: '',
    txtMensaje: '',
    cbDepto: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: newTicketSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        showWarning(
          intl.formatMessage({id: 'WARNING.CONFIRM.TICKET'}),
          intl.formatMessage({id: 'WARNING.CONFIRM.TICKET.MESSAGE'})
        ).then((r) => {
          if (!r.isConfirmed) return

          const nuevoTicket: CreateTicketDTO = {
            Asunto: values.txtAsunto,
            Mensaje: values.txtMensaje,
            Departamento: values.cbDepto,
            UsuarioId: user.Id,
          }

          createTicket(nuevoTicket)
            .then(() => {
              dispatchEvent(new Event(ON_TICKET_SUBMIT))
              toggleModal()
              toastSuccess(intl.formatMessage({id: 'SUCCESS.SEND.TICKET'}))
              formik.resetForm()
            })
            .catch((e) => {
              showError(
                intl.formatMessage({id: 'ERROR.SENDING.TICKET'}),
                errorMessageBuilder(e, intl.formatMessage),
                intl.formatMessage({id: 'HEADER.ACCEPT'})
              )
            })
            .finally(() => {
              setSubmitting(false)
              setLoading(false)
            })
        })
      }, 1000)
    },
  })

  const btnCerrarFormHandler = () => {
    toggleModal()
    formik.resetForm()
  }

  return (
    <>
      <Modal show={isOpen} size='lg'>
        <Modal.Header>
          <Modal.Title>{intl.formatMessage({id: 'MENU.NEW_TICKET'})}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Body>
            <div className='alert alert-primary d-flex align-items-center p-5 mb-3'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                <KTSVG path='/media/icons/duotune/general/gen044.svg' />
              </span>
              <span>{intl.formatMessage({id: 'INFO.TICKET'})}</span>
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.SUBJECT'})}</label>
              <input
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.TICKET_SUBJECT'})}
                type='text'
                {...formik.getFieldProps('txtAsunto')}
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
              />
              {formik.touched.txtAsunto && formik.errors.txtAsunto && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.txtAsunto}</span>
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.DEPARTMENT'})}</label>
              <select {...formik.getFieldProps('cbDepto')} className='form-select'>
                <option hidden value='0'>
                  {intl.formatMessage({id: 'PLACEHOLDER.TICKET_DEPARTMENT'})}
                </option>
                <option value='admin'>{intl.formatMessage({id: 'DEPARTMENT.ADMIN'})}</option>
                <option value='soporte-tecnico'>
                  {intl.formatMessage({id: 'DEPARTMENT.SUPPORT'})}
                </option>
              </select>
              {formik.touched.cbDepto && formik.errors.cbDepto && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.cbDepto}</span>
                </div>
              )}
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'HEADER.MESSAGE'})}</label>
              <textarea
                placeholder={intl.formatMessage({id: 'PLACEHOLDER.TICKET_MESSAGE'})}
                autoComplete='off'
                {...formik.getFieldProps('txtMensaje')}
                className='form-control form-control-lg form-control-solid'
                style={{height: '150px'}}
              />
              {formik.touched.txtMensaje && formik.errors.txtMensaje && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{formik.errors.txtMensaje}</span>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' name='btnCerrarForm' onClick={btnCerrarFormHandler}>
              {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
            </Button>
            <Button
              variant='primary'
              name='btnAceptarForm'
              type='submit'
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                loading ||
                formik.getFieldProps('cbDepto').value === ''
              }
            >
              {loading
                ? intl.formatMessage({id: 'SETTINGS.LOADING'})
                : intl.formatMessage({id: 'SETTINGS.ACCEPT'})}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default NuevoTicket
