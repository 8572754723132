import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const GET_GRAFICO_CONTROL = `${API_URL}/reportes/sedes/:sid/indicadores/:iid/graficos-control`
export const GET_TABLA_SEGUIMIENTO = `${API_URL}/reportes/sedes/:sid/indicadores/:iid/tabla-seguimiento`
export const GET_AUDITORIA = `${API_URL}/reportes/sedes/:sid/indicadores/:iid/auditoria`
export const GET_INDICADORES_PENDIENTES = `${API_URL}/reportes/sedes/:id/indicadores/pendientes-carga`
export const GET_GENERAL_INSTITUCION = `${API_URL}/reportes/instituciones/general`
export const GET_MEDICIONES_SEGUN_ESTADO = `${API_URL}/reportes/instituciones/indicadores/:id/mediciones-estado-sedes`
export const GET_SEDES_SIN_MEDICIONES = `${API_URL}/reportes/instituciones/indicadores/:id/sedes-sin-reportar`
export const GET_TABLA_RESUMEN_INDICADOR = `${API_URL}/reportes/instituciones/indicadores/:id/mediciones-sedes-periodo`
export const GET_INTERLABORATORIO = `${API_URL}/reportes/sedes/:sid/indicadores/:iid/interlaboratorio`

export function getGraficoControl(
  sedeId: string,
  indicadorId: string,
  fechaInicio: string,
  fechaFin: string
) {
  return axios.get(GET_GRAFICO_CONTROL.replace(':sid', sedeId).replace(':iid', indicadorId), {
    params: {desde: fechaInicio.replaceAll('-', '/'), hasta: fechaFin.replaceAll('-', '/')},
  })
}

export function getTablaSeguimiento(
  sedeId: string,
  indicadorId: string,
  fechaInicio: string,
  fechaFin: string
) {
  return axios.get(GET_TABLA_SEGUIMIENTO.replace(':sid', sedeId).replace(':iid', indicadorId), {
    params: {desde: fechaInicio.replaceAll('-', '/'), hasta: fechaFin.replaceAll('-', '/')},
  })
}

export function getAuditoria(
  sedeId: string,
  indicadorId: string,
  fechaInicio: string,
  fechaFin: string
) {
  return axios.get(GET_AUDITORIA.replace(':sid', sedeId).replace(':iid', indicadorId), {
    params: {desde: fechaInicio.replaceAll('-', '/'), hasta: fechaFin.replaceAll('-', '/')},
  })
}

export function getIndicadoresPendientes(sedeId: string) {
  return axios.get(GET_INDICADORES_PENDIENTES.replace(':id', sedeId))
}

export function getReporteGeneralInstitucion() {
  return axios.get(GET_GENERAL_INSTITUCION)
}

export function getMedicionesSegunEstado(estado: string, indicadorId: string) {
  return axios.get(GET_MEDICIONES_SEGUN_ESTADO.replace(':id', indicadorId), {params: {estado}})
}

export function getSedesSinMediciones(indicadorId: string) {
  return axios.get(GET_SEDES_SIN_MEDICIONES.replace(':id', indicadorId))
}

export function getResumenIndicador(
  indicadorId: string,
  sedesEncoded: string[],
  desde: string,
  hasta: string
) {
  return axios.get(GET_TABLA_RESUMEN_INDICADOR.replace(':id', indicadorId), {
    params: {desde, hasta, sedesEncoded: sedesEncoded.join(',')},
  })
}

export function getInterlaboratorio(indicadorId: string, sedeId: string, periodo: string) {
  return axios.get(GET_INTERLABORATORIO.replace(':iid', indicadorId).replace(':sid', sedeId), {
    params: {periodo},
  })
}

export function getGraficosControlPDF(
  sedeEncoded: string,
  indicadorEncoded: string,
  desde: string,
  hasta: string,
  lang: string
) {
  return axios.get(
    `${GET_GRAFICO_CONTROL.replace(':sid', sedeEncoded).replace(':iid', indicadorEncoded)}/pdf`,
    {
      params: {desde: desde.replaceAll('-', '/'), hasta: hasta.replaceAll('-', '/'), lang},
    }
  )
}

export function getTablaSeguimientoPDF(
  sedeEncoded: string,
  indicadorEncoded: string,
  desde: string,
  hasta: string,
  lang: string
) {
  return axios.get(
    `${GET_TABLA_SEGUIMIENTO.replace(':sid', sedeEncoded).replace(':iid', indicadorEncoded)}/pdf`,
    {
      params: {desde: desde.replaceAll('-', '/'), hasta: hasta.replaceAll('-', '/'), lang},
    }
  )
}

export function getAuditoriaPDF(
  sedeEncoded: string,
  indicadorEncoded: string,
  desde: string,
  hasta: string,
  lang: string
) {
  return axios.get(
    `${GET_AUDITORIA.replace(':sid', sedeEncoded).replace(':iid', indicadorEncoded)}/pdf`,
    {
      params: {desde: desde.replaceAll('-', '/'), hasta: hasta.replaceAll('-', '/'), lang},
    }
  )
}

export function getInterlaboratorioPDF(
  indicadorId: string,
  sedeId: string,
  periodo: string,
  lang: string,
  grupos: string
) {
  return axios.get(
    `${GET_INTERLABORATORIO.replace(':iid', indicadorId).replace(':sid', sedeId)}/pdf`,
    {
      params: {periodo, lang, grupos},
    }
  )
}

export function getGeneralInstitucionPDF(lang: string) {
  return axios.get(`${GET_GENERAL_INSTITUCION}/pdf`, {params: {lang}})
}

export function getResumenIndicadorPDF(
  indicadorId: string,
  sedesEncoded: string,
  desde: string,
  hasta: string,
  lang: string
) {
  return axios.get(`${GET_TABLA_RESUMEN_INDICADOR.replace(':id', indicadorId)}/pdf`, {
    params: {desde, hasta, sedesEncoded: sedesEncoded, lang},
  })
}
