import {FC, useEffect, useState} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {ValueWithPrecisionDTO} from '../../../../models/DTOs/Indicadores/ValueWithPrecisionDTO'
import {LimiteDTO} from '../../../../models/DTOs/Indicadores/LimiteDTO'
import {useIntl} from 'react-intl'
import {formatoExpresionHandler} from '../../../../../_metronic/helpers'

type Props = {
  title: string
  indicador: {
    Codigo: string
    Nombre: string
    FormatoExpresion: string
    ValorObjetivo: LimiteDTO
    ValorLimite: LimiteDTO
  }
  valorInformado: ValueWithPrecisionDTO
  evaluacionValor: {
    ValorInformado?: ValueWithPrecisionDTO
    Estado?: string
    Periodo: string
    Mediana?: ValueWithPrecisionDTO
    CantMediciones: number
  }
  estado: string
}

const DesempenioObjetivoLimite: FC<Props> = ({
  title,
  valorInformado,
  evaluacionValor,
  indicador,
  estado,
}) => {
  const [options, setOptions] = useState({})

  const intl = useIntl()

  const max = Math.max(
    valorInformado.Value,
    indicador.ValorLimite.Valor.Value,
    indicador.ValorObjetivo.Valor.Value
  )
  const min = Math.min(
    0,
    valorInformado.Value,
    indicador.ValorLimite.Valor.Value,
    indicador.ValorObjetivo.Valor.Value
  )

  useEffect(() => {
    setOptions({
      chart: {
        type: 'column',
        marginRight: 60,
        height: 250,
        width: 350,
      },
      title: {
        text: `${title}`,
        style: {
          fontSize: '15px',
        },
      },
      subtitle: {
        align: 'center',
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: `${intl.formatMessage({id: 'HEADER.VALUE'})} (${formatoExpresionHandler(
            indicador.FormatoExpresion,
            intl
          )})`,
        },
        min: min,
        max: max,
        plotLines: [
          {
            color: 'red',
            width: 3,
            value: indicador.ValorLimite.Valor.Value,
            zIndex: 5,
          },
          {
            color: 'green',
            width: 3,
            value: indicador.ValorObjetivo.Valor.Value,
            zIndex: 5,
          },
        ],
      },
      xAxis: {
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        column: {
          pointWidth: 60,
          grouping: false,
          minPointLength: 3,
        },
        series: {
          groupPadding: 0.5,
          events: {
            legendItemClick: function () {
              return false
            },
          },
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
        },
      },
      series: [
        {
          color: 'red',
          name: 'Valor limite',
        },
        {
          type: 'column',
          name: intl.formatMessage({id: 'HEADER.PERFORMANCE'}),
          color:
            estado === 'rojo'
              ? '#ff7f7f'
              : estado === 'amarillo'
              ? '#ffff7f'
              : estado === 'verde'
              ? '#7fbf7f'
              : 'gray',
          data: [
            {
              y: valorInformado.Value,
              x: 0,
              color:
                estado === 'rojo'
                  ? '#ff7f7f'
                  : estado === 'amarillo'
                  ? '#ffff7f'
                  : estado === 'verde'
                  ? '#7fbf7f'
                  : 'gray',
            },
          ],
          tooltip: {
            headerFormat: null,
            pointFormat: `<b>${intl.formatMessage({id: 'HEADER.REPORTED_VALUE'})}</b>: {point.y}`,
          },
        },
        {
          color: 'green',
          name: 'Valor objetivo',
        },
      ],
    })
  }, [])

  return (
    <div style={{pageBreakInside: 'avoid'}}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default DesempenioObjetivoLimite
