import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../../../models/UserModel'
import {getUserByToken} from './CRUD/AuthCRUD'
import {UsuarioSedesDTO} from '../../../models/DTOs/Sedes/UsuarioSedesDTO'
import {getSedesUsuario} from './CRUD/UsuariosSedesCRUD'
import {setLanguage} from '../../../../_metronic/i18n/Metronici18n'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Loggedout: '[Loggedout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateToken: '[UpdateToken] Action',
  SetSede: '[Set Sede] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  sede: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  hash?: string
  sede?: UsuarioSedesDTO
}

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_AUTH_KEY!,
    whitelist: ['user', 'accessToken', 'refreshToken', 'sede'],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {accessToken, refreshToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        const sede = action.payload?.sede
        return {...state, user, sede}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        if (user) {
          const idioma = user.Idioma === 'español' ? 'es' : 'en'
          setLanguage(idioma)
        }
        return {...state, user}
      }

      case actionTypes.UpdateToken: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {...state, accessToken, refreshToken}
      }

      case actionTypes.SetSede: {
        const sede = action.payload?.sede
        return {...state, sede}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.Login,
    payload: {accessToken, refreshToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel, sede: UsuarioSedesDTO) => ({
    type: actionTypes.UserLoaded,
    payload: {user, sede},
  }),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateToken: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.UpdateToken,
    payload: {accessToken, refreshToken},
  }),
  setSede: (sede: UsuarioSedesDTO) => ({type: actionTypes.SetSede, payload: {sede}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    const {data: sedes} = yield getSedesUsuario()
    if (user) {
      const idioma = user.Idioma === 'español' ? 'es' : 'en'
      setLanguage(idioma)
    }
    yield put(
      actions.fulfillUser(
        user,
        sedes.find((sede: any) => sede.IsActivo)
      )
    )
  })
}
