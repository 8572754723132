import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {showError} from '../../../_metronic/helpers/AlertHelper'
import {getRespuestasPruebaPilotoStatus} from '../../modules/auth/redux/CRUD/PruebaPilotoCRUD'
import {errorMessageBuilder} from '../../modules/errors/error-message-builder'

const PruebaDeshabilitada: FC = () => {
  const [respuestasStatus, setRespuestasStatus] = useState<boolean | undefined>(undefined)

  const intl = useIntl()

  useEffect(() => {
    getRespuestasPruebaPilotoStatus()
      .then((r) => setRespuestasStatus(r.data))
      .catch((e) =>
        showError(
          'Error al cargar las respuestas',
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  return (
    <>
      {!respuestasStatus ? (
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
            <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
              <a href='/dashboard' className='mb-10 pt-lg-1'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logo_gm_qi.png')}
                  className='h-150px'
                />
              </a>
              <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
                {intl.formatMessage({id: 'PP.DISABLED'})}
              </h1>
              <div className='fw-bold fs-3 text-gray-600 mb-15'>
                {intl.formatMessage({id: 'PP.START_DATE'})}
              </div>
              {/* <div className='pt-lg-10 mb-10'>
            <div className='text-center'>
              <a className='btn btn-lg btn-primary fw-bolder'>
                {intl.formatMessage({id: 'ERROR.BUTTON'})}
              </a>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
            <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
              <a href='/dashboard' className='mb-10 pt-lg-1'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/logo_gm_qi.png')}
                  className='h-150px'
                />
              </a>
              <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
                {intl.formatMessage({id: 'PP.QUESTIONS_ANSWERED'})}
              </h1>
              <div className='fw-bold fs-3 text-gray-600 mb-15'>
                {intl.formatMessage({id: 'PP.QUESTIONS_ANSWERED_START_DATE'})}
              </div>
              {/* <div className='pt-lg-10 mb-10'>
          <div className='text-center'>
            <a className='btn btn-lg btn-primary fw-bolder'>
              {intl.formatMessage({id: 'ERROR.BUTTON'})}
            </a>
          </div>
        </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PruebaDeshabilitada
