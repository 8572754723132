import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const CREATE_SEDE_INDICADORES = `${API_URL}/sedes/:id/indicadores`
export const GET_RELACIONES_SEDE_INDICADORES = `${API_URL}/sedes/:id/indicadores`
export const CREATE_SEDES_INDICADORES = `${API_URL}/batch/sedes/indicadores`
export const GET_RELACIONES_SEDES_INDICADORES = `${API_URL}/sedes/indicadores`
export const GET_SEDES_INDICADORES_INFO = `${API_URL}/batch/sedes/indicadores/info/:idsEncoded`

export function createSedeIndicadores(sedeId: string, indicadoresId: string[]) {
  return axios.post(CREATE_SEDE_INDICADORES.replace(':id', sedeId), {Indicadores: indicadoresId})
}

export function getRelacionesSedeIndicadores(sedeId: string) {
  return axios.get(GET_RELACIONES_SEDE_INDICADORES.replace(':id', sedeId))
}

export function createSedesIndicadores(relaciones: {SedeId: string; IndicadorId: string}[]) {
  return axios.post(CREATE_SEDES_INDICADORES, relaciones)
}

export function getRelacionesSedesIndicadores() {
  return axios.get(GET_RELACIONES_SEDES_INDICADORES)
}

export function getSedesIndicadoresInfo(idsEncoded: string) {
  return axios.get(GET_SEDES_INDICADORES_INFO.replace(':idsEncoded', idsEncoded))
}
