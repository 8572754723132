import {FC, useEffect, useState} from 'react'
import {getPruebaPilotoStatus} from '../../modules/auth/redux/CRUD/PruebaPilotoCRUD'
import {showError} from '../../../_metronic/helpers/AlertHelper'
import {errorMessageBuilder} from '../../modules/errors/error-message-builder'
import {useIntl} from 'react-intl'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import {FallbackView} from '../../../_metronic/partials'
import PruebaDeshabilitada from './PruebaDeshabilitada'
import PruebaPreguntas from './PruebaPreguntas'
import PruebaProcesandoResultados from './PruebaProcesandoResultados'
import Mantenimiento from './Mantenimiento'

const PruebaPiloto: FC = ({children}) => {
  const [pilotoStatus, setPilotoStatus] = useState<string>('')

  const intl = useIntl()

  useEffect(() => {
    getPruebaPilotoStatus()
      .then((r) => {
        setPilotoStatus(r.data)
      })
      .catch((e) =>
        showError(
          'Error al cargar el estado',
          errorMessageBuilder(e, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      )
  }, [])

  switch (pilotoStatus) {
    case 'procesando-resultados':
      return <PruebaProcesandoResultados />
    case 'disabled':
      return <PruebaDeshabilitada />
    case 'institution-add-info':
      return <PruebaPreguntas />
    case 'mantenimiento':
      return <Mantenimiento />
    case 'enabled':
      return <>{children}</>
    default:
      return <FallbackView />
  }
}

export default PruebaPiloto
