import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  estado?: string
}

const EstadoDesempenioGrupoTag: FC<Props> = ({estado}) => {
  const intl = useIntl()

  if (!estado)
    return (
      <span className='badge badge-dark'>
        {intl.formatMessage({id: 'PERFORMANCE.STATUS.ERROR'})}
      </span>
    )

  if (estado === 'aceptable')
    return (
      <span className='badge badge-primary'>
        {intl.formatMessage({id: 'PERFORMANCE.STATUS.ACCEPTABLE'})}
      </span>
    )

  if (estado === 'excelente')
    return (
      <span className='badge badge-success'>
        {intl.formatMessage({id: 'PERFORMANCE.STATUS.EXCELLENT'})}
      </span>
    )

  if (estado === 'cuestionable')
    return (
      <span className='badge badge-warning'>
        {intl.formatMessage({id: 'PERFORMANCE.STATUS.QUESTIONABLE'})}
      </span>
    )

  return (
    <span className='badge badge-dark'>{intl.formatMessage({id: 'PERFORMANCE.STATUS.ERROR'})}</span>
  )
}

export default EstadoDesempenioGrupoTag
