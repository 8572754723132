import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const LIST_PREGUNTAS = `${API_URL}/piloto/preguntas`
export const SEND_RESPUESTAS = `${API_URL}/piloto/respuestas`
export const GET_PILOTO_STATUS = `${API_URL}/piloto/status`
export const GET_RESPUESTAS_STATUS = `${API_URL}/piloto/respuestas/status`

export function listPreguntasPruebaPiloto() {
  return axios.get(LIST_PREGUNTAS)
}

export function sendRespuestasPruebaPiloto(respuestas: {PreguntaId: string; Valor: string}[]) {
  return axios.post(SEND_RESPUESTAS, respuestas)
}

export function getPruebaPilotoStatus() {
  return axios.get(GET_PILOTO_STATUS)
}

export function getRespuestasPruebaPilotoStatus() {
  return axios.get(GET_RESPUESTAS_STATUS)
}
