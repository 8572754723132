import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './CRUD/AuthCRUD'
import {RootState} from '../../../../setup'
import {getSedesUsuario} from './CRUD/UsuariosSedesCRUD'
import {UsuarioSedesDTO} from '../../../models/DTOs/Sedes/UsuarioSedesDTO'
import {setLanguage} from '../../../../_metronic/i18n/Metronici18n'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const sedeActual: UsuarioSedesDTO = useSelector<RootState>(
    ({auth}) => auth.sede,
    shallowEqual
  ) as UsuarioSedesDTO

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          const {data: sedes} = await getSedesUsuario()
          // const idioma = user.Idioma === 'español' ? 'es' : 'en'
          // setLanguage(idioma)
          if (sedeActual) {
            dispatch(props.fulfillUser(user, sedeActual))
          } else {
            dispatch(
              props.fulfillUser(
                user,
                sedes.find((sede: any) => sede.IsActivo)
              )
            )
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
