import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {CreateIndicadorDTO} from '../../../../models/DTOs/Indicadores/CreateIndicadorDTO'

export const GET_INDICADORES = `${API_URL}/indicadores`
export const GET_INDICADOR = `${API_URL}/indicadores/:id`
export const GET_INDICADOR_INFO = `${API_URL}/indicadores/:id/info`
export const GET_INDICADORES_PREDEFINIDOS = `${API_URL}/indicadores/predefinidos`
export const CREATE_INDICADOR = `${API_URL}/indicadores`
export const UPDATE_INDICADOR_STATUS = `${API_URL}/indicadores/:id/status`
export const UPDATE_INDICADOR = `${API_URL}/indicadores/:id`
export const GET_SEDES_BY_INDICADOR = `${API_URL}/indicadores/:id/sedes`

export function getIndicadores() {
  return axios.get(GET_INDICADORES)
}

export function getIndicador(indicadorId: string) {
  return axios.get(GET_INDICADOR.replace(':id', indicadorId))
}

export function getIndicadorInfo(indicadorId: string) {
  return axios.get(GET_INDICADOR_INFO.replace(':id', indicadorId))
}

export function getIndicadoresPredefinidos() {
  return axios.get(GET_INDICADORES_PREDEFINIDOS)
}

export function createIndicador(request: CreateIndicadorDTO) {
  return axios.post(CREATE_INDICADOR, request)
}

export function updateIndicadorStatus(indicadorId: string) {
  return axios.post(UPDATE_INDICADOR_STATUS.replace(':id', indicadorId))
}

export function updateIndicador(indicadorId: string, updateIndicador: CreateIndicadorDTO) {
  return axios.post(UPDATE_INDICADOR.replace(':id', indicadorId), updateIndicador)
}

export function getSedesByIndicador(indicadorId: string) {
  return axios.get(GET_SEDES_BY_INDICADOR.replace(':id', indicadorId))
}
