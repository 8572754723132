import {FC} from 'react'

type Props = {
  color: string
  className?: string
}

const BulletComponent: FC<Props> = ({color, className}) => {
  let colorBullet = ''

  if (color === 'rojo') colorBullet = 'red'
  else if (color === 'amarillo') colorBullet = 'yellow'
  else if (color === 'verde') colorBullet = 'green'

  return (
    <li className={`d-flex align-items-center py-2 ${className}`}>
      <span
        className={`bullet h-15px w-50px me-5`}
        style={{background: colorBullet, opacity: 0.5}}
      />
    </li>
  )
}

export default BulletComponent
